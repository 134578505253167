.main .inner-container {
  display: block;
}

.alert-warning {
  z-index: 10000000;
  position: relative;
}

// Homepage
// =========
.hp-content {
  .case-study-section {
    border-bottom: none;
    padding-bottom: 90px;
    padding-top: 110px;
    border-top: 1px solid #e8e8e8;

    .section-intro {
      margin-bottom: 10px;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 18px;
    }

    .section-footer {
      margin-top: 30px;
    }

    .bottom-stats {
      text-align: center;

      h4 {
        font-weight: 300;
        margin-bottom: 15px;
      }

      .col-md-4  {
        border: 1px solid #eee;
        border-left: none;
        padding-top: 28px;
        padding-bottom: 28px;
      }

      .col-md-4.first {
        border-left: 1px solid #eee;
        strong {
          color: #f6c475;
        }
      }

      .col-md-4.second {
        strong {
          color: #f6c475;
        }
      }

      .col-md-4.third {
        strong {
          color: #f6c475;
        }
      }

      strong {
        display: block;
        font-size: 35px;
        line-height: 1.0em;
      }

      span {
        font-weight: 300;
      }
    }

    .case-study {
      // border: 1px solid #e8e8e8;
      border: 1px solid #f9f9f9;
      box-shadow: 0 4px 42px rgba(0, 0, 0, 0.03);
      padding: 0;
      padding-right: 20px;
      margin-bottom: 30px;

      p {
        font-size: 14px;
      }

      // &:not(:last-child) {
      //   border-bottom: none;
      // }
      .cs-logo-wrap {
        margin-top: 30px;
        display: block;
      }

      .image-link {
        margin-bottom: 0;
        display: block;
        float: left;
        margin-right: 24px;

        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          // background: -webkit-linear-gradient(left,rgba(55,58,75,.79),rgba(121,146,59,.79));
          // background: linear-gradient(90deg,rgba(55,58,75,.79),rgba(121,146,59,.79));
          background: rgba(255,194,102,.82);
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 81px;
          height: 100%;
          background: url(../images/banner-side-angle-left.svg) no-repeat 100% 0;
        }
      }

      h5 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;

        a {
          color: #373a3c;
        }

        span {
          font-weight: 100;
          display: block;
          font-size: 13px;
          margin-top: 2px;
        }

        img {
          margin-right: 10px;
          width: 40px;
          margin-top: -3px;
          float: left;
          @include border-radius(100%);
        }
      }

      blockquote {
        font-size: 14px;
        font-style: italic;

        i {
          margin-right: 8px;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .top-banner {
    .top-banner__overlay {
      width: 130%;
      height: 480px;
      position: absolute;
      bottom: -305px;
      left: -10%;
      background: white;
      transform: rotate(-10deg);

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -20px;
        width: 100%;
        height: 20px;
        background: rgba(255, 255, 255, 0.12);
      }
    }

    & > .row {
      position: relative;
      // background: url(../images/pixelter-banner3.png) no-repeat;
      // background-position: center center;
      // background-size: cover;
      overflow: hidden;

      // &:after {
      //   content: "";
      //   width: 100%;
      //   height: 170px;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   background: url(../images/white-angle-left-right.png) no-repeat left bottom;
      // }

      & > .col-md-12 {
        background: rgba(73, 85, 41, 0.9);
        // background: linear-gradient(to right, rgba(55, 58, 75, 0.89), rgba(121, 146, 59, 0.91));
        // background: linear-gradient(90deg,rgba(55,58,75,.89),rgba(121, 146, 59, 0.82));
        background: linear-gradient(to right,rgba(255, 41, 80, 1),rgba(255, 194, 102, 1));
      }

      // &:after {
      //   content: "";
      //   width: 100%;
      //   height: 320px;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   background: url(../images/hp-banner-overlay.svg) no-repeat left bottom;
      //   background-size: contain;
      // }
    }

    .banner-content {
      padding-top: 160px;
      padding-bottom: 200px;
      // height: 730px;
      position: relative;
      z-index: 10;

      // & > .row {
      //   height: 100vh;
      //   align-items: center;
      // }
    }

    .banner-img {
      position: absolute;
      right: -50px;
      top: 0;
      width: 640px;
      bottom: 0;
    }

    .inner-container {
      // width: $inner-conteiner-lg-max-width;
      width: 1350px;
      padding: 0 15px;
    }

    h2 {
      font-size: 65px;
      font-weight: bold;
      line-height: 65px;
      margin-bottom: 0;
      color: #fff;
      text-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
      // background: #79923B;
      // padding: 20px 20px 20px 40px;
    }

    p {
      font-size: 20px;
      line-height: 1.35em;
      margin-bottom: 40px;
      // max-width: 500px;
      margin: 0 0 5px;
      // background: #fff;
      // color: #111;
      color: #fff;
      // padding: 25px 40px;
      padding: 18px 0;
      max-width: 545px;
      // font-weight: 300;

      // strong {
      //   font-weight: bold;
      //   color: #79923b;
      // }
    }

    .banner-usp-list {
      padding: 0;
      margin: 0 0 35px;
      list-style: none;
      color: #fff;
      // font-weight: 300;
      font-size: 14px;
      max-width: 500px;

      li {
        float: left;
        width: 50%;
        margin-bottom: 15px;
        background: url(../images/white-check-mark.svg) no-repeat left center;
        background-size: 13px;
        padding-left: 23px;
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .btn-primary {
      padding: 15px 40px;
      // background-color: #79923b;
      // border-color: #79923b;
      // font-weight: bold;
      font-size: 18px;
      position: relative;
      z-index: 10;
      background-color: #fff;
      border-color: #fff;
      color: #BF2447;
      // font-weight: bold;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        opacity: .8;
      }
    }

    .secondary-cta {
      color: #8e8d8d;
      font-size: 13px;
      width: 200px;
      display: block;
      clear: both;
      margin: 20px auto 0;
      text-decoration: underline;
    }

    .trust-logos {
      text-align: center;
      padding-top: 8px;
      padding-bottom: 8px;

      p, img {
        display: inline-block;
        vertical-align: middle;
      }

      p {
        margin: 0 30px 0 0;
        line-height: 90px;
        font-size: 16px;
      }
    }
  }

  .clients-section {
    // padding-top: 55px;
    padding-top: 25px;
    padding-bottom: 115px;
    // border-bottom: 1px solid #e8e8e8;

    h3 {
      margin-bottom: 14px;
      font-size: 21px;
    }

  }

  .hp-plans {
    // border-bottom: 1px solid #e8e8e8;

    .plans-title {
      h3 {
        text-align: center;
        font-size: 40px;
      }
    }

    .line-divider {
      height: 1px;
      background: #e8e8e8;
      // transform: rotate(-7deg);
      margin-bottom: 130px;

      &.line-divider--bottom {
        margin-top: 130px;
        margin-bottom: 100px;
      }
    }

    .plan-wrap {
      max-width: 100%;
    }

    .row {
      align-items: center;
      
      & + .row {
        margin-top: 70px;
      }
    }

    p {
      clear: both;
      background: #fff;
      padding: 7px 0 0;
      line-height: 1.45em;
      margin-bottom: 0;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      span {
        display: block;
        font-weight: 300;

        strong {
          font-weight: bold;
        }
      }

      a {
        display: block;
        float: left;
        margin-top: 18px;
        font-size: 14px;
        // color: #79923b;
        // border-bottom: 1px dotted #79923b;
        // text-decoration: none !important;
        // -webkit-transition: all .3s ease-in-out;
        // transition: all .3s ease-in-out;
        // position: relative;

        &.btn-primary {
          padding: .45rem 1rem;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 0;
          height: 1px;
          background: #647733;
          -webkit-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
        }

        // &:hover {
        //   color: #647733;
        //   // border-bottom-color: #647733;
        //   // border-bottom-style: solid;
        //
        //   &:after {
        //     width: 100%;
        //   }
        // }
      }
    }

    h3 {
      // background: #79923b;
      // color: #fff;
      // padding: 8px 18px;
      font-weight: bold;
      // float: left;
      margin: 0;
      // font-size: 23px;
      font-size: 30px;
      margin-bottom: 12px;

      .icon {
        margin-right: 5px;
        margin-top: -6px;
      }
    }

    .conversion-audit-img,
    .shopify-development-img,
    .conversion-boost-img {
      text-align: center;
      // &:before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   // background: linear-gradient(90deg,rgba(121, 146, 59, 0.79),rgba(55, 58, 75, 0.79));
      //   // background: rgba(55, 58, 75, 0.8);
      //   background: rgba(255, 194, 102, 0.82);
      // }

      // &:after {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 81px;
      //   height: 100%;
      //   background: url(../images/banner-side-angle-right.svg) no-repeat top right;
      // }
    }

    .conversion-audit-img {
      // &:after {
      //   // right: 0;
      //   // left: auto;
      //   // background: url('../images/banner-side-angle-left.svg') no-repeat top right;
      // }

      // &:before {
      //   // background: linear-gradient(90deg,rgba(55, 58, 75, 0.79),rgba(121, 146, 59, 0.79));
      //   // background: rgba(55, 58, 75, 0.8);
      // }
    }

    .conversion-audit-img {
      // background: url(../images/conversion-audit.png) no-repeat center center;
      // background-size: cover;
    }

    .conversion-boost {
      .plan-wrap {
        float: right;
      }
    }

    .shopify-development-img {
      // background: url(../images/shopify-package.png) no-repeat center center;
      // background-size: cover;
    }

    .shopify-development {
      .plan-wrap {
        float: right;
      }
    }

    .conversion-boost-img {
      // background: url(../images/conversion-boost.png) no-repeat center center;
      // background-size: cover;
    }

    .conversion-boost,
    .shopify-development {
      .plan-wrap {
        padding-right: 25px;
      }
    }

    .conversion-audit {
      .plan-wrap {
        padding-left: 25px;
      }
    }
  }

  .top-section {
    padding-top: 90px;
    padding-bottom: 120px;
    // background: #fbfbfb;


    .section-intro {
      margin-bottom: 60px;

      h3 {
        background: #ffc266;
        color: #fff;
        padding: 10px 20px;
        text-transform: uppercase;
        font-size: 21px;
        margin-bottom: 6px;
        float: left;
        font-weight: bold;
      }

      h2 {
        clear: both;
        font-size: 46px;
        line-height: 1.25em;
        margin-bottom: 35px;

        strong {
          text-transform: none;
          display: inline;
        }
      }

      p {
        font-size: 20px;

        strong {
           font-weight: bold;
        }
      }
    }

    .section-content {
      ul {
        padding: 0;
        list-style: none;
      }

      .intro {
        font-size: 19px;
        margin-bottom: 25px;
      }

      .image-wrap img {
        margin-top: -25px;
      }

      .content-wrap {
        padding-top: 0px;
      }

      li {
        margin-bottom: 40px;
        position: relative;
        // padding-left: 60px;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 4px;
      }

      p {
        font-size: 16px;
        color: #555;
      }

      i {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: $brand-primary;
        @include border-radius(100%);
        color: #fff;
        display: none;
      }
    }
  }
  .section-clients {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;

    h3 {
      margin-bottom: 21px;
      font-size: 23px;
      font-weight: bold;
      text-align: center;

    }

    .client-logos {
      padding-top: 50px;

      h5 {
        margin-bottom: 15px;
        font-size: 14px;
      }
    }

    blockquote {
      padding: 22px;
      padding-left: 47px;
      border-radius: 5px;
      font-size: 15px;
      line-height: 22px;
      font-style: italic;
      // border: 1px solid #e8e8e8;
      border: 1px solid #f9f9f9;
      box-shadow: 0 4px 42px rgba(0, 0, 0, 0.03);
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid #fff;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        position: absolute;
        bottom: -12px;
        left: 24px;
      }

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid #f3f3f3;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        position: absolute;
        bottom: -13px;
        left: 24px;
      }

      i {
        position: absolute;
        left: 14px;
        top: 22px;
        font-size: 24px;
        // color: #e4e4e4;
        // color: #9fbb58;
        color: #FFC266;
      }

      & + p {
        font-size: 15px;
        color: #848484;
        line-height: 1.3em;

        .clinet-li {
          color: #0076b5;
          margin-left: 4px;
          font-size: 17px;
          vertical-align: middle;
          margin-left: 3px;
        }

        span {
          // font-weight: bold;
          padding-top: 15px;
          color: #111;
          display: block;
        }

        img {
          margin-right: 12px;
          border-radius: 100%;
          margin-left: 7px;
          float: left;
        }
      }
    }
  }

  .middle-section {
    margin-top: -180px;

    & > .row {
      // background: #eff0f0;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      padding-top: 140px;
      padding-bottom: 0;
    }

    .section-clients {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 80px;

        h3 {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: bold;
        }
    }

    .stages {
      margin-bottom: 30px;
      .stage {
        border-top: 7px solid #ebeff0;
        padding-top: 20px;

        &.stage-2 {
          border-color: rgba(159, 187, 88, 0.3);
        }
        &.stage-3 {
          border-color: rgba(159, 187, 88, 0.6);
        }
        &.stage-4 {
          border-color: #9FBB58;
        }
      }

      h3 {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;

        span {
          font-size: 18px;
          display: block;
          font-weight: normal;
          margin-top: 8px;
          text-transform: none;
          margin-bottom: 16px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }

  .bottom-section {
    border-top: 1px solid #e8e8e8;

    & > .row {
      padding-top: 90px;
      padding-bottom: 80px;
    }

    &.content-section .section-intro {
      margin-bottom: 20px
    }

    .single-testimonial {
      position: relative;
      border: 1px solid #e8e8e8;
      margin-bottom: 10px;

      img {
        max-width: 100%;
        height: auto !important;
      }

      h4 {
        margin-bottom: 15px;
      }

      h5 {
        font-size: 15px;
        font-weight: bold;
        float: left;
        margin-bottom: 20px;

        span {
          font-weight: 100;
        }

        img {
          margin-right: 10px;
          @include border-radius(100%);
        }
      }

      a.btn {
        display: block;
        float: left;
        clear: both;
        font-size: 14px;
      }

      i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 24px;
        color: #e4e4e4;
      }

      blockquote {
        position: relative;
        padding-left: 36px;
        font-size: 14px;
        font-style: italic;
      }

      .testimonial-image {
        padding-left: 0;
      }

      .testimonial-text {
        padding-top: 30px;
        // padding-bottom: 30px;
      }
    }
  }

  .lead-magnet {
    & > .row {
      padding-top: 90px;
      padding-bottom: 80px;
      background: #3C3C3C;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }

    &.content-section .section-intro {
      margin-bottom: 20px
    }

    h2 {
      color: #fff;
      margin-bottom: 36px !important;

       strong {
         display: inline !important;
       }
    }

    .ebook {
      box-shadow: 2px -2px 0px 0px #fff,
                  3px -3px 0px 0px #ccc,
                  5px -5px 0px 0px #fff,
                  6px -6px 0px 0px #ccc,
                  8px -8px 0px 0px #fff,
                  9px -9px 0px 0px #ccc;
      width: 242px;
      border: 1px solid rgba(255, 255, 255, 0.14);
    }

    .content-wrap {
      p {
        font-size: 15px;
        margin-bottom: 12px;
        color: #dad9d9;
        margin-top: 0;
      }

      form {
        margin-top: 25px;
        p {
          margin-bottom: 8px;
        }
        input[type=text],
        input[type=email] {
          padding: 0 10px;
          height: 40px;
          width: 250px;
          font-size: 14px;
          border-color: #fff;
        }
      }
    }
  }

  .bottom-cta {
    margin-bottom: 60px;
  }
} // Homepage

// Online Tools
// =========
body.tools {

  .main .page-header h1 {
    margin-bottom: 0;
  }

  .main-content-wrap {
    padding-top: 0;
  }

  .online-tool-banner + .online-tool-banner {
    margin-top: 25px;
  }

  .online-tool-banner {
    height: 300px;
    background-size: cover !important;
    position: relative;

    .coming-soon {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(46, 48, 61, 0.85);
      text-align: center;
      line-height: 300px;
      color: #fff;
      font-weight: 300;
      font-size: 26px;
    }

    .banner-content-wrap {
      padding: 42px 95px 0 30px;
      height: 100%;
      width: 480px;
      max-width: 100%;
      background: url(../images/tool-banner-overlay.svg) no-repeat left top;
    }

    .btn {
      color: #fff;
      border-color: rgba(255, 255, 255, 0.6);
      font-weight: 300;
      font-size: 14px;
      padding: 8px 33px;

      &:hover {
        border-color: #9fbb58;
      }
    }

    p {
      color: #fff;
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 14px;
      width: 300px;
      max-width: 100%;
    }

    .titles {
      margin-bottom: 12px;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      h3, h4 {
        float: left;
        clear: both;
        color: #fff;
        margin: 0;
      }

      h4 {
        background: #A2C058;
        font-weight: bold;
        padding: 8px 16px;
        font-size: 16px;
      }

      h3 {
        background: #79923B;
        font-weight: 100;
        font-size: 23px;
        padding: 12px 16px;
      }
    }
  }
}

// Sections
.content-section {
  .section-intro {
    margin-bottom: 40px;

    h2 {
      font-size: 45px;
      font-weight: 100;
      margin-bottom: 26px;

      strong {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
      }
    }


    p {
      font-weight: 300;
      color: #3e3e3e;
      font-size: 21px;
      line-height: 1.5em;

      strong {
        font-weight: bold;
      }
    }
  }

  .section-intro,
  .section-cta,
  .section-content {
    width: 100%;
  }
} // Sections

// Subpages
// =========
.main {
  .page-header {
    & > .row {
      padding-top: 140px;
      padding-bottom: 70px;
      // background: url(../images/subpage-banner.png);
      // background-position: bottom left;
    }

    h1 {
      font-size: 55px;
      font-weight: bold;
      line-height: 60px;
      color: #373a3c;
      margin-bottom: 15px
    }

    p {
      color: #4e4e4e;
      font-size: 19px;
      line-height: 1.5em;
      // font-weight: 300;
      margin-bottom: 0;
    }
  }

  .main-content-wrap {
    padding-top: 90px;
    padding-bottom: 80px;
  }
  .cta-banner {
    width: 100%;

    .row {
      background: #3c3c3c;
      padding-top: 35px;
      padding-bottom: 35px;
      @include border-radius(4px);

      & > div {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    h3 {
      color: #fff;
      float: left;
      font-weight: bold;
      margin: 0;
      font-size: 23px;
      padding: 0;
      background: none;
    }

    p {
      float: left;
      clear: left;
      margin: 0;
      font-weight: 100;
      font-size: 21px;
      color: #fff;
    }

    .btn {
       float: right;
       margin-top: 5px;
    }
  }

  .check-list {
    margin: 0 0 30px;
    padding: 0;

    li {
      padding: 0 0 0 45px;
      margin-bottom: 25px;
      list-style: none;
      font-size: 15px;
      line-height: 1.5em;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "\f00c";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        top: 0;
        left: 0;
        color: $brand-primary;
        width: 30px;
        height: 30px;
        border: 1px solid $brand-primary;
        @include border-radius(100%);
        text-align: center;
        line-height: 30px;
      }
    }
  }

  .plus-list {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;

    li {
      font-size: 14px;
      padding-left: 20px;
      position: relative;
      margin-bottom: 4px;

      &:before {
        content: "\f067";
        font: normal normal normal 14px/1 FontAwesome;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 4px;
        color: $brand-primary;
      }
    }
  }
} // Subpages

// Infographics
// =========
.page-template-tempalte-infographics {
  .container {
    background: none;
  }

  .main-content-wrap {
    text-align: center;
  }

  .main-content-wrap .inf-wrap {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.18);
    position: relative;
    display: inline-block;
    background: #fff;

    img {
      margin: 0;
    }

    .cta {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 300px;
      width: 100%;
      display: block;
    }
  }
}

// Pricing
// =========
.pricing-page {

  &.page-template-template-pricing-v2 {
    .main .main-content-wrap {
        padding-top: 30px;
    }

    .main .page-header>.row {
      padding-bottom: 30px;
    }

    .services-wrap {
      margin-top: 30px;

      .row {
        align-items: center;

        & + .row {
          margin-top: 110px;
        }
      }

      .row.cro-package {
        margin-top: 90px;
      }

      .service-image {
        position: relative;

        // &:before {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   width: 100%;
        //   height: 100%;
        //   // background: linear-gradient(90deg,rgba(55, 58, 75, 0.79),rgba(121, 146, 59, 0.79));
        //   background: rgba(255,194,102,.82);
        // }

        // &:after {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   width: 81px;
        //   height: 100%;
        //   background: url('../images/banner-side-angle-left.svg') no-repeat top right;
        // }
      }

      // .audit-package {
      //   border-top: 1px solid #e8e8e8;
      // }

      .audit-package .service-image {
        // background: url(../images/audit-package.png) no-repeat center left;
        // background-position: center center;
        // background-size: cover;

        // &:after {
        //   background: url('../images/banner-side-angle-right.svg') no-repeat top right;
        //   left: 0;
        // }

        &:before {
          // background: linear-gradient(90deg,rgba(121, 146, 59, 0.79),rgba(55, 58, 75, 0.79));
        }
      }

      .cro-package .service-image {
        // background: url(../images/cro-package.png) no-repeat center left;
        // background-position: center center;
        // background-size: cover;
      }

      .shopify-dev .service-image {
        // background: url(../images/shopify-package.png) no-repeat center left;
        // background-position: center center;
        // background-size: cover;
      }

      .service-content:not(.pull-lg-6) {
        padding-left: 50px;
      }

      .service-content.pull-lg-6 {
        padding-right: 60px;

        .service-content-inner {
          float: right;
        }
      }

      // .shopify-dev {
      //   border-bottom: 1px solid #e8e8e8;
      // }

      .service-content {
        // border-top: 1px solid #e8e8e8;

        .service-content-inner {
          max-width: 390px;
        }

        // padding-top: 60px;
        // padding-bottom: 60px;

        h2 {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 1.85rem;

          .icon {
            margin-right: 8px;
          }

          a {
            text-decoration: none;
            color: #373a3c;

            &:hover {
              text-decoration: none;
              color: #FFC266;
            }
          }

          i {
            color: #FFC266;
          }
        }

        p.package-tip {
          line-height: 1.2em;
          font-style: italic;
        }

        p {
          font-size: 15px;
        }
      }

      .audit-package {
        .service-content {
          h2 a {
            white-space: nowrap;
          }
        }
      }
    }

    .pricing-wrap {
      & > .row {
        border: none;
      }

      p.package-tip {
        font-size: 16px;
      }

      .package-cost {
        font-weight: bold;
      }

      .top-plan {
        text-align: center;
      }

      .col-md-12 {
        padding: 0 15px !important;
      }

      h2 {
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: #373a3c;

          &:hover {
            text-decoration: none;
            color: #9fbb58;
          }
        }

        i {
          color: #9fbb58;
        }
      }

      p {
        font-size: 15px;
      }

      .row {
        margin-bottom: 0;
      }

      .row>div {
        text-align: left;
        padding: 40px 40px;
      }
    }

    .plan-details {
      .inner-container .row {
        margin-top: 90px;
        border-top: 1px solid #e8e8e8;
        padding-top: 80px;
      }

      ul {
        li {
          margin-bottom: 2px;
        }
      }

      .plan-2 {
        .plan-title-wrap {
          i {
            font-size: 42px;
          }
        }
      }

      .plan-title-wrap {
        position: relative;
        padding-left: 66px;

        i {
          color: #9fbb58;
          font-size: 50px;
          position: absolute;
          left: 0;
          top: 5px;
        }
      }

      h2 {
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 0;
      }

      h3 {
        font-size: 23px;
        margin-bottom: 30px;
        font-weight: 100;
      }

      h4 {
        font-weight: bold;
        font-size: 26px;
        margin-top: 40px;
      }

      h5 {
        margin-bottom: 0;
        font-weight: bold;
      }

      .plan-pricing-wrap {
        background: #2e303d;
        padding: 30px;
        margin-top: 42px;
        border-radius: 4px;
        @include clearfix;

        .left {
          width: 60%;
          float: left;
        }
        .right {
          width: 40%;
          float: right;
        }

        h5 {
          color: #fff;
        }

        p {
          font-weight: 300;
          color: #fff;
          margin: 0;
        }

        .btn {
          float: right;
        }
      }

      .step-content {
        border: 1px solid #dadada;
        border-top: none;
        padding: 25px;
        margin-bottom: 40px;

        .step {
          display: none;

          &.active {
            display: block;
          }
        }
      }

      .step-list {
        margin-top: 40px;
        margin-bottom: 0;
        @include clearfix;

        .step {
          padding-left: 25px;
          background: #ebeff0;
          padding-top: 15px;
          padding-bottom: 15px;
          width: 25%;
          float: left;
          border-top: 1px solid #ebeff0;
          cursor: pointer;
          position: relative;

          i {
            position: absolute;
            right: 20px;
            top: 40px;
          }

          h4 {
            margin: 0;
            font-size: 18px;

            span {
              display: block;
              font-weight: 300;
              text-transform: uppercase;
              font-size: 13px;
              margin-bottom: 4px;
            }
          }

          p:last-child {
            margin-bottom: 0;
          }

          &.active {
            background: #fff !important;
            border-color: #dadada !important;

            i {
              display: none;
            }
          }

          &.step-1 {
            border-left: 1px solid #ebeff0;
          }

          &.step-2 {
            background: #e2ebcd;
            border-top-color: #e2ebcd;
          }

          &.step-3 {
            background: #c5d69b;
            border-top-color: #c5d69b;
          }

          &.step-4 {
            background: #9FBB58;
            border-top-color: #9FBB58;
            border-right: 1px solid #9FBB58;
          }
        }
      }

      // p,li {
      //   font-size: 17px;
      // }

      .icon-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 40px;
        margin-top: 30px;

        li {
          position: relative;
          padding-left: 60px;
          margin-bottom: 8px;
        }

        strong {
          display: block;
        }

        i {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          width: 40px;
          height: 40px;
          background: #9fbb58;
          border-radius: 4px;
          text-align: center;
          line-height: 40px;
        }
      }

      // p, ul {
      //   max-width: 680px;
      // }
    }

  }

  .pricing-page-wrap {
    &.country-gb {
      .price-usd {
        display: none;
      }
    }

    &:not(.country-gb) {
      .price-gbp {
        display: none;
      }
    }
  }

  .pricing-wrap {
    width: 100%;

    & > .row {
      background: url(../images/plans-bg.png) no-repeat center center;
      background-size: cover;
      padding-top: 50px;
      padding-bottom: 50px;

      .col-md-6 {
        background: #fff;
        padding: 25px 30px;
      }

      .col-md-6 + .col-md-6 {
        border-left: 1px solid #eee;
      }
    }

    .row {
      // border: 1px solid #e8e8e8;
      margin-bottom: 40px;

      & > div {
        // border-right: 1px solid #e8e8e8;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 25px;
        padding-bottom: 25px;

        &:last-child {
          border-right: none;
        }
      }

      .top-plan-wrap {
        // z-index: 10;
        // box-shadow: 0 0 20px rgba(0,0,0,0.2);
      }
    }

    .btn {
      font-size: 14px;
    }

    .price-detail {
      font-size: 11px;
      font-style: italic;
      color: #a0a0a0;
    }

    .top-plan {
      display: none;
      // position: absolute;
      // top: -32px;
      // left: 5%;
      // width: 90%;
      // background: $brand-primary;
      // color: #fff;
      // padding: 5px 20px;
      // font-weight: 300;
      // text-transform: uppercase;
      // font-size: 14px;
      // box-shadow: 0 0 20px rgba(0,0,0,0.2);

      i {
        margin-right: 5px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    p {
      font-size: 13px;
    }

    p.package-tip {
      font-style: italic;
      font-size: 14px;
    }

    li {
      font-size: 14px;

      p {
        margin: 0;
      }

      .label {
        text-transform: uppercase;
        font-weight: 300;
      }
      .info {
        font-weight: bold;
      }
    }

    ul {
      padding: 0;
      margin: 20px 0 0;
      list-style: none;

      li {
        margin-bottom: 12px;
        padding-bottom: 12px;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 150px;
          height: 1px;
          background: #dedede;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -75px;
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .faq {
    padding-top: 100px;
    // margin-top: 90px;
    // border-top: 1px solid #e8e8e8;

    .row {
      min-width: 100%;
    }

    .faq-title {
      text-align: center;
      margin-bottom: 25px;
      width: 100%;
      border-top: 1px solid #e8e8e8;
      padding-top: 70px;
    }

    .faq-questions {
      margin-bottom: 40px;

      h5 {
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  .bottom-cta {
    text-align: center;
    padding-top: 40px;

    .row {
      min-width: 100%;
    }

    h3 {
      margin-bottom: 22px;
    }

    .btn {
      margin-right: 20px;
    }

    i {
      margin-right: 5px;
    }

    .inner-container {
      border-top: 1px solid #e8e8e8;
      padding-top: 50px;
    }
  }
} // Pricing


// Our Approach
// ========
body.page-template.our-approach {
  .header-bg {
    // background: linear-gradient(90deg,rgba(55,58,75,1) 30%,rgba(121,146,59,1));
    background: linear-gradient(to right,#FF2950 30%,#FFC266);
    height: 210px;
    // margin-bottom: 65px;
    margin-bottom: 40px;
  }

  .main-content {
    .bottom-cta {
      margin-bottom: 100px;
    }

    .layer-list {
      margin-bottom: 60px;

      .layer-1 .layer-image {
        background: url(../images/approach-ux.jpg);
      }

      .layer-2 .layer-image {
        background: url(../images/approach-emotion.jpg);
      }

      .layer-3 .layer-image {
        background: url(../images/approach-conserns.jpg);
      }

      .layer-4 .layer-image {
        background: url(../images/approach-trigger.jpg);
      }

      .layer-5 .layer-image {
        background: url(../images/approach-value.jpg);
      }

      .layer-6 .layer-image {
        background: url(../images/approach-habbit.jpg);
      }

      .layer-7 .layer-image {
        background: url(../images/approach-ai.jpg);
      }

      .layer-item {
        border: 1px solid #f9f9f9;
        box-shadow: 0 7px 42px rgba(0,0,0,.03);
        margin-bottom: 30px;
        position: relative;

        &:before,
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 80px solid transparent;
          border-right: 80px solid transparent;
          position: absolute;
          left: calc(50% - 80px);
          z-index: 10;
        }


        &:before {
          border-top: 50px solid #f2f0f0;
          bottom: -51px;
        }

        &:after {
          border-top: 50px solid #fff;
          bottom: -50px;
        }

        &:last-child {
          &:before,
          &:after {
            display: none;
          }
        }

        .layer-image {
          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            // background: -webkit-linear-gradient(left,rgba(55,58,75,.79),rgba(121,146,59,.79));
            // background: linear-gradient(90deg,rgba(55,58,75,.79),rgba(121,146,59,.79));
            background: rgba(255,194,102,.82);
            z-index: 2;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 81px;
            height: 100%;
            background: url(../images/banner-side-angle-left.svg) no-repeat 100% 0;
            z-index: 3;
          }
        }


        .layer-text {
          padding-top: 60px;
          padding-bottom: 60px;
          padding-right: 30px;
          padding-left: 40px;

          h4 {
            font-size: 19px;
            margin-bottom: 5px;
          }

          h2 {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .examples {
            // margin: 30px 0 0;
            margin: 0;
            padding: 0;
            list-style: none;
            // border-top: 1px solid #eee;
            // border-left: 1px solid #eee;

            &:after {
              content: "";
              display: block;
              clear: both;
            }

            li {
              float: left;
              width: 50%;
              list-style: none;
              font-size: 14px;
              padding: 9px 0;
              // border-right: 1px solid #eee;
              // border-bottom: 1px solid #eee;
              // padding: 12px 20px;

              i {
                color: #ff2950;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .section-title {
    margin-bottom: 85px;

    & > .row {
      background: #fff;
      // background: linear-gradient(to right,#FF2950 30%,#FFC266);
      position: relative;

      & > .col-md-12 {
        // padding-bottom: 95px;
        padding-top: 20px;
      }

      // &:after {
      //   content: "";
      //   display: block;
      //   height: 94px;
      //   width: 100%;
      //   background: url(../images/banner-arrow.svg) no-repeat center bottom;
      //   position: absolute;
      //   left: 0;
      //   bottom: 0;
      // }
    }

    .section-intro {
      text-align: center;

      h3 {
        font-weight: bold;
        font-weight: 300;
        margin-bottom: 3px;
        // color: #fff;
      }

      h1 {
        font-weight: bold;
        font-size: 48px;
        margin-bottom: 10px;
        // color: #fff;
      }

      span {
        display: inline-block;
        padding: 6px 14px;
        border: 1px solid #FFC266;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 30px;
        color: #F08260;
      }

      p {
        font-size: 19px;
        // color: #fff;
      }
    }

    .layer-img {
      padding-right: 0;
    }

    .layer-list {
      font-size: 16px;
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 28px;
        padding: 0;
        list-style: none;
        position: relative;
        padding-left: 42px;
        // color: #fff;

        // &:nth-child(1) {
        //   span {
        //     border-color: #A2C058;
        //     color: #A2C058;
        //   }
        // }
        //
        // &:nth-child(2) {
        //   span {
        //     border-color: #8BAB3E;
        //     color: #8BAB3E;
        //   }
        // }
        //
        // &:nth-child(3) {
        //   span {
        //     border-color: #667E2B;
        //     color: #667E2B;
        //   }
        // }
        //
        // &:nth-child(4) {
        //   span {
        //     border-color: #546725;
        //     color: #546725;
        //   }
        // }
        //
        // &:nth-child(5) {
        //   span {
        //     border-color: #43531D;
        //     color: #43531D;
        //   }
        // }
        //
        // &:nth-child(6) {
        //   span {
        //     border-color: #262F12;
        //     color: #262F12;
        //   }
        // }
        //
        // &:nth-child(7) {
        //   span {
        //     border-color: #2E303D;
        //     color: #2E303D;
        //   }
        // }
      }

      span {
        position: absolute;
        left: 0;
        top: -2px;
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border: 1px solid #FFC266;
        border-radius: 100%;
        font-size: 14px;
        // font-weight: bold;
        color: #F08260;
      }
    }
  }

}


// Plans
// ========
.page-template-template-plan-wide,
.page-template-template-plan {

  .page-header .col-lg-8 {
    left: 0;
    text-align: left !important;
  }

  .page-header h1 {
      color: #fff;
      // background: #79923b;
      // padding: 20px 25px;
      display: inline-block;
      // white-space: nowrap;
      line-height: 1em;
      margin-bottom: 12px;
  }

  .page-header p {
    // background: #8bab3e;
    // padding: 20px 25px;
    color: #fff;
    display: inline-block;
    clear: both;
    // font-weight: 100;
    text-transform: capitalize;
  }

  &.conversion-audit,
  &.conversion-boost,
  &.shopify-development {
    .main .page-header>.row {
      position: relative;

      &:before {
        content: "";
        display: block;
        // background: linear-gradient(90deg,rgba(55,58,75,.89) 30%,rgba(121,146,59,.91));
        // background: linear-gradient(90deg,rgba(55,58,75,.79) 30%,rgba(121,146,59,.79));
        background: linear-gradient(to right,rgba(255,42,80.1,.95),rgba(255,194,102,.9));
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.conversion-audit {
    .main .page-header>.row {
      // background: url(../images/audit-header.png) no-repeat center center;
      // background-size: cover;
      margin-bottom: 55px;

      &:before {
        background: linear-gradient(90deg,rgba(255,42,80,1),rgba(255,194,102,1));
      }
    }

    .audit-example-wrap {
      text-align: center;


      .btn {
        margin-top: 10px;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  &.conversion-boost {
    .main .page-header>.row {
      // background: url(../images/boost-header.png) no-repeat center center;
      // background-size: cover;
      margin-bottom: 55px;

      &:before {
        background: linear-gradient(90deg,rgba(255,42,80,1),rgba(255,194,102,1));
      }
    }
  }


  &.shopify-development {
    .main .page-header>.row {
      // background: url(../images/shopify-banner.png) no-repeat center center;
      // background-size: cover;
      margin-bottom: 55px;

      &:before {
        background: linear-gradient(90deg,rgba(255,42,80,1),rgba(255,194,102,1));
      }

      h1 {
        white-space: nowrap;
      }
    }

    .vp-list {
        .col-lg-6 {
          margin-bottom: 65px;
        }
        h4 {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 4px;
        }

        p {
          font-size: 15px;
        }

        img {
          margin-bottom: 20px;
        }
      }

    .main .main-content-wrap {
      .cro-stage+.cro-stage {
        margin-top: 0;
      }

      .top-into-image {
        text-align: center;
      }

      h4 {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .cro-stage {
        padding-bottom: 55px;
        padding-left: 48px;
        border-left: 1px dashed #e5e5e5;
        position: relative;

        &.last {
          padding-bottom: 0;
        }

        h3 {
          margin-bottom: 15px;
          font-size: 18px;
          position: relative;
          z-index: 5;
        }

        &:before {
          content: "";
          display: block;
          left: 0;
          top: 22px;
          width: 100%;
          position: absolute;
          border-top: 1px dashed #e5e5e5;
        }

        &:after {
          content: "";
          display: block;
          left: -5px;
          top: 18px;
          width: 10px;
          height: 10px;
          position: absolute;
          background: #F08260;
          border-radius: 100%;
        }
      }
    }

    .portfolio-wrap {
      background-repeat: no-repeat;
      background-position: bottom right;
      margin: 0 -15px;
      border-bottom: 1px solid #eee;

      .portfolio-img {
        max-width: none;
        position: absolute;
        bottom: 0;
        right: 100%;
      }


      .portfolio-content--left {
        .portfolio-img {
          right: auto;
          left: 100%;
        }
      }

      &.last {
        // border-bottom: 1px solid #e5e5e5;
        margin-bottom: 90px;
      }

      &.portfolio-wrap-dark {
        background-color: #FDFAFA;
        background-position: bottom left;

        .portfolio-content {
          padding-left: 40px;
        }
      }

      &:not(.potfolio-wrap-dark) {
        .portfolio-content {
          padding-right: 40px;
        }
      }

      .portfolio-content {
        min-height: 640px;
        padding-top: 120px;

        blockquote {
          padding: 15px 22px 15px;
          padding-left: 47px;
          border-radius: 5px;
          font-size: 14px;
          line-height: 20px;
          font-style: italic;
          border: 1px solid #f9f9f9;
          box-shadow: 0 4px 42px rgba(0,0,0,.03);
          position: relative;
          background: #fff;
          margin-top: 25px;

          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 12px solid #fff;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            position: absolute;
            bottom: -12px;
            left: 18px;
          }

          &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 12px solid #f3f3f3;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            position: absolute;
            bottom: -13px;
            left: 18px;
          }

          i {
            position: absolute;
            left: 14px;
            top: 15px;
            font-size: 24px;
            // color: #e4e4e4;
            color: #FFC266;
          }

          & + p {
            font-size: 13px;
            color: #848484;

            span {
             color: #111;
             display: block;
           }

           .name {
             font-weight: bold;
             padding-top: 6px;
             line-height: 1.1em;

             .clinet-li {
               color: #0076b5;
               margin-left: 6px !important;
               font-size: 18px;
               vertical-align: middle;
               margin-left: 3px;
             }
           }

            img {
              margin-right: 12px;
              border-radius: 100%;
              margin-left: 7px;
              width: 50px;
              float: left;
            }
          }
        }
      }
    }

    .portfolio-intro-wrap {
      background: #FDFAFA;
      margin: 0 -15px;
      padding-top: 65px;
      padding-bottom: 0;
      margin-top: 60px;
      border-top: 1px solid #eee;

      h2 {
        margin: 0 !important;
      }
    }

    .section-intro {
      h2 {
        font-size: 45px;
        margin-bottom: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 60px;

        .shopify-logo {
          position: relative;
          top: -7px;
          margin: 0 4px;
        }
      }
    }
  }


  .main .main-content-wrap {
    padding-top: 0;

    .package-img {
      margin: 25px 0 35px;
    }

    h4 {
      font-weight: bold;
    }

    p + .audit-content-list {
      margin-top: 24px;
    }

    .testimonial-divider {
      clear: both;
      display: block;
      margin: 30px 0;
      width: 100%;
    }

    .pricing-title {
      margin-bottom: 55px;

      h2 {
        text-align: center;
        font-size: 45px;
        font-weight: 300;
      }
    }

    .pricing-package {
      border: 1px solid #f5f3f3;
      box-shadow: 0 4px 42px rgba(0,0,0,.03);
      background: #fff;
      text-align: center;
      padding-bottom: 108px;
      margin-bottom: 30px;

      &.recommended {
        position: relative;
        z-index: 5;
        transform: scale(1.05);

        &:before {
          content: "Most Popular";
          position: absolute;
          left: 50px;
          right: 50px;
          top: -12px;
          line-height: 25px;
          background: #FFC266;
          color: #fff;
          font-size: 13px;
        }
      }

      .pricing-package-inner {
        padding: 30px 10px 0;
      }

      h4 {
        font-weight: bold;
        margin: 0 0 5px;
        font-size: 25px;
      }

      .summary {
        font-size: 14px;
        border-bottom: 1px solid #eee;
        padding-bottom: 2rem;
        line-height: 1.3em;
      }

      .timeline {
        line-height: 1.2em;
        font-size: 14px;
        margin-bottom: 1.5rem;

        strong {
          display: block;
        }
      }

      .timeline,
      .price {
        border-bottom: 1px solid #eee;
        padding-bottom: 1rem;
      }

      .price {
        font-size: 22px;
        color: #758e3b;
      }

      .deliverables {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;

        li {
          background: url(../images/check-green.svg) no-repeat left 6px;
          padding-left: 26px;
          background-size: 12px;
          font-size: 14px;
          margin-bottom: 6px;
        }
      }

      .cta {
        margin: 0;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 1rem;
        border-top: 1px solid #eee;
        padding-top: 1rem;

        .btn {
          padding: .45rem 1.6rem;
        }
      }
    }

    .audit-content-list {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
      padding: 0;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      &.audit-content-list-flat {
        box-shadow: 0 0 20px rgba(0,0,0,.03);
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;

        li {
          width: 50%;
          float: left;
          clear: none;
          border-right: none;
        }
      }

      li {
        margin-bottom: 0;
        list-style: none;
        border: 1px solid #eee;
        padding: 11px 20px;
        border-bottom: none;
        font-size: 14px;

        span {
          color: #FF2950;
          margin-right: 9px;
        }

        &.title-item {
          background: #9fbb58;
          color: #fff;
          font-weight: bold;
          font-size: 17px;
        }
      }
    }

    .lg-testimonial-logo {
      text-align: center;

      .testimonial-name {
        margin-top: 7px;
        font-weight: bold;

        .clinet-li {
          color: #0076b5;
          margin-left: 4px;
          font-size: 21px;
          vertical-align: middle;
          margin-left: 3px;
        }
      }

      .testimonial-name,
      .testimonial-info {
        font-size: 13px;
        line-height: 1.2em;
      }
    }

    .lg-testimonial-text {
      p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
        position: relative;
        padding-left: 34px;
        color: #444;

        i {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 25px;
          color: #FFC266;
        }
      }
    }

    .plan-tabs {
      margin-top: 70px;
      margin-bottom: 65px;

      .plan-tab {
        margin-bottom: 9px;

        h3 {
          cursor: pointer;
          // background: #f1f1f1;
          font-size: 19px;
          margin-top: 0;
          padding: 14px 25px;
          margin-bottom: 0;
          margin-top: 0;
          // border: 1px solid #e8e8e8;
          background: #79923b;
          color: #fff;

          &:after {
            content: "+";
            display: block;
            float: right;
            // color: #79923b;
            color: #fff;
          }
        }

        .tab-content {
          max-height: 0;
          transition: max-height 0.3s ease-out;
          overflow: hidden;

          .tab-content-inner {
            border: 1px solid #e8e8e8;
            padding: 25px 25px;
            border-top: none;
          }

          p, li {
            font-size: 17px;
          }
        }

        &.open {
          .tab-content {
            max-height: 2500px;
            transition: max-height 0.2s ease-in;
          }

          h3 {
            &:after {
              content: "-";
            }
          }
        }
      }
    }

    .boost-staps {
      padding-top: 15px;

      .step {
        padding-left: 25px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-left: 6px solid red;

        .list-intro {
          margin-bottom: 5px;
        }

        h4 {
          font-size: 19px;
          font-weight: bold;

          span {
            display: block;
            font-size: 15px;
            font-weight: normal;
            margin-bottom: 2px;
          }
        }
      }
    }

    p, li {
      font-size: 16px;
      clear: both;
    }

    .divider-wrap {
      margin-top: 90px;
      margin-bottom: 90px;
    }

    .intro-plan > p {
      font-size: 22px;
      font-weight: 300;
      margin-bottom: 60px;
      position: relative;
      border-left: 5px solid #FFC266;
      padding-left: 40px;
      line-height: 1.45em;

      & > strong {
        font-weight: bold;
      }
    }

    .plan-clients {
      h6 {
        font-weight: bold;
      }
    }

    .plan-bottom-cta {
      padding-top: 30px;

      .plan-testimonial {
        margin-bottom: 40px;
      }
    }

    .plan-testimonial {

      h5 {
        margin-bottom: 13px;
      }
      blockquote {
        padding: 22px;
        padding-left: 47px;
        border-radius: 5px;
        font-size: 15px;
        line-height: 22px;
        font-style: italic;
        border: 1px solid #e8e8e8;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid #fff;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          bottom: -12px;
          left: 24px;
        }

        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid #e8e8e8;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          bottom: -13px;
          left: 24px;
        }

        i {
          position: absolute;
          left: 14px;
          top: 22px;
          font-size: 24px;
          // color: #e4e4e4;
          // color: #9fbb58;
          color: #FFC266;
        }

        & + p {
          font-size: 14px;
          color: #848484;

          span {
            // font-weight: bold;
            color: #111;
          }

          img {
            margin-right: 12px;
            border-radius: 100%;
            margin-left: 7px;
          }
        }
      }
    }

    .contact-form {
      form {
        padding: 40px 60px;
        border: 1px solid #f9f9f9;
        box-shadow: 0 4px 42px rgba(0,0,0,.03);

        h4 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 13px;
        }
      }

      .wpcf7-not-valid-tip {
        font-size: 13px;
        margin-top: 3px;
      }

      div.wpcf7-validation-errors {
        font-size: 14px;
      }

      input[type=text], input[type=email], textarea {
        border: 1px solid #ccc;
      }

      textarea {
        resize: none;
        height: 110px;
      }

      .custom-dropdown {
        width: 100%;
        height: 40px;
        background: #fff;
      }

      .form-info {
        margin: 10px 0 0;
        font-size: 14px;
        line-height: 1.4em;

        i {
          margin-right: 5px;
        }
      }
    }

    h3 {
      // margin-top: 40px;
      font-weight: bold;
      background: #FFC266;
      color: #fff;
      padding: 10px 20px;
      float: left;
      font-size: 21px;
      margin-bottom: 21px;
    }

    .cro-stage {
      h3 {
        padding: 0;
        box-shadow: 0 4px 32px rgba(0,0,0,.02);

        .stage {
          display: block;
          float: left;
          padding: 14px 20px 14px 20px;
        }

        .step-name {
          display: block;
          float: left;
          padding: 14px 20px 14px 15px;
          color: #3C3C3C;
          background: #fff;
          font-weight: normal;
          border: 1px solid #f9f9f9;
          border-left: none;
        }
      }

      & + .cro-stage {
        margin-top: 55px;
      }
    }

    .process-header {
      text-align: center;
      margin-bottom: 40px;

      h3 {
        font-size: 40px;
        margin-bottom: 0;
        float: none;
        display: inline-block;
      }

      h4 {
        display: inline-block;
        background: #FFC266;
        color: #fff;
        font-weight: normal;
        padding: 10px 20px;
        font-size: 21px;
      }
    }

    .icon-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 40px;
      margin-top: 30px;

      li {
        position: relative;
        padding-left: 60px;
        margin-bottom: 8px;
      }

      strong {
        display: block;
      }

      i {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        width: 40px;
        height: 40px;
        background: #9fbb58;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
      }
    }
  }
}

// Contact
// ========
.page-template-template-landing-page-full,
.page-template-template-landing-page,
.page-template-template-contact {

  .main .page-header>.row {
    padding-bottom: 20px;
  }

  .contact-content {
    .inner-container {
      padding-top: 30px;
      padding-bottom: 80px;
    }

    .client-logos {
      margin-top: 60px;

      h6 {
        font-size: 15px;
      }
    }

    .bottom-content .inner-container {
      padding-top: 80px;
    }

    .office-location {
      margin-bottom: 60px;

      h5 {
        font-size: 22px;
        margin-bottom: 30px;
      }
    }

    .contact-testimonial {

      h5 {
        margin-bottom: 13px;
      }
      blockquote {
        padding: 22px;
        padding-left: 47px;
        border-radius: 5px;
        font-size: 15px;
        line-height: 22px;
        font-style: italic;
        border: 1px solid #e8e8e8;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid #fff;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          bottom: -12px;
          left: 24px;
        }

        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid #e8e8e8;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          bottom: -13px;
          left: 24px;
        }

        i {
          position: absolute;
          left: 14px;
          top: 22px;
          font-size: 24px;
          // color: #e4e4e4;
          color: #FFC266;
        }

        & + p {
          font-size: 15px;
          color: #848484;

          span {
            // font-weight: bold;
            color: #111;
          }

          img {
            margin-right: 12px;
            border-radius: 100%;
            margin-left: 7px;
          }
        }
      }
    }

    h5 {
      font-size: 17px;
      font-weight: bold;
    }

    h2 {
      margin-bottom: 25px;
    }

    ul {
      padding: 0;
      margin: 0 0 50px;

      li {
        padding: 0 0 0 45px;
        margin-bottom: 20px;
        list-style: none;
        font-size: 15px;
        line-height: 1.5em;
        position: relative;

        &:before {
          content: "\f00c";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          position: absolute;
          top: 0;
          left: 0;
          color: #FFC266;
          width: 30px;
          height: 30px;
          border: 1px solid #FFC266;
          @include border-radius(100%);
          text-align: center;
          line-height: 30px;
        }
      }
    }
  }

  .bottom-content {
    border-top: 1px solid #e8e8e8;

    .inner-container {
        display: block;
    }

    h2 {
      text-align: center;
      margin-bottom: 50px;
      font-size: 40px;
    }

    .contact-map {
      img {
        border-radius: 40px;
      }

      a.maps-link {
        display: block;
        text-align: center;
        margin-top: 10px;
      }
    }

    .contact-info {
      padding-top: 8px;

      p {
        position: relative;
        padding-left: 55px;
        margin-bottom: 40px;

        a {
          color: #373a3c;

          &:hover {
            color: #9fbb58;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        i {
          position: absolute;
          left: 0;
          top: -8px;
          color: #FFC266;
          width: 40px;
          height: 40px;
          border: 1px solid #FFC266;
          border-radius: 100%;
          text-align: center;
          line-height: 39px;
        }
      }
    }
  }

  .contact-form-wrap {
    form {
      padding: 30px 30px;
      // border: 1px solid #dadada;
      // background: #e8e8e8;
      border: 1px solid #f9f9f9;
      box-shadow: 0 4px 42px rgba(0,0,0,.03);

      h4 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }

    .custom-dropdown {
      width: 100%;
      height: 40px;
    }

    input[type=text], input[type=email] {
      border: 1px solid #ccc;
    }

    .form-info {
      margin: 10px 0 0;
      font-size: 14px;
      line-height: 1.4em;

      i {
        margin-right: 5px;
      }
    }
  }
} // Contact


// Landing Page
// ========
.custom-order-confirm {
  .thank-you-content {
    border-top: 1px solid #e8e8e8;
  }

  .main .page-header>.row {
    padding-bottom: 110px;
  }

  &.page-template-template-thank-you .thank-you-content {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &.page-template-template-thank-you .thank-you-content h2 {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 14px;
  }

  &.page-template-template-thank-you .thank-you-content p {
    font-size: 18px;
    text-align: center;
    max-width: 565px;
    margin: 0 auto 25px;
  }

  .main .page-header p strong {
    font-weight: bold;
  }
}

// UX Assessment Landing Page
.page-template-template-landing-page.page-id-1691 {
  .landing-page-title {
    h1 {
      font-size: 52px;
    }
  }

  .lp-offer-banner {
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #79923b;
    margin-bottom: 20px;
    border-radius: 5px;
    color: #fff;
  }


  .contact-form-wrap {
    h4 {
      font-size: 15px;
      margin-bottom: 15px;
    }
    label.req:after {
      content: "*";
      color: #d04f4f;
    }
  }
}


// Landing Page
// ========
.page-template-template-landing-page-full {
  .contact-content .inner-container {
    padding-top: 10px !important;
  }
  .inner-container {

    .main-content {
      padding-right: 40px;
    }

    p,li {
      font-size: 17px;
    }

    h3 {
      margin-top: 35px;
      font-weight: 700;
      font-size: 24px
    }

    .video-wrap {
      margin-bottom: 20px;
    }

    .contact-form-wrap {
      margin-bottom: 30px;
    }

    .special-offer {
      display: none;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #79923b;
      margin-bottom: 40px;
      border-radius: 5px;

      p {
        font-weight: 300;
        color: #fff;
        margin: 0;
        font-size: 15px;

        &.top {
          font-size: 19px;
        }

        strong {
          font-weight: bold;
        }
      }
      .cross {
        text-decoration: line-through;
      }
    }

    .cta-promo {
      display: none;
    }

    .cta-wrap {
      padding: 25px;
      border: 1px solid #dadada;
      background: #e8e8e8;
      margin-bottom: 30px;

      .price {
        margin: 15px 0 0;
        font-style: italic;

        .cross {
          text-decoration: line-through;
        }
      }

      h4 {
        font-weight: bold;
        font-size: 23px;
      }

      p {
        font-size: 15px;

        i {
          margin-right: 2px;
        }
      }
    }

    .main-content {
      .cta-wrap {
        text-align: center;
        margin-top: 40px;
      }
    }

    .side-info {
      h5 {
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 40px;
      }

      .contact-testimonial {
        blockquote+p {
          font-size: 13px;

          img {
            width: 50px;
            margin: 0 8px 0 0;
            float: left;
          }

          span {
            display: block;
            font-size: 15px;
            padding-top: 4px;
          }
        }
      }
    }

    .contact-form-wrap {
      form {
        padding: 22px;
        border: 1px solid #dadada;
        background: #e8e8e8;

        h4 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 13px;
        }
      }

      input[type=text], input[type=email] {
        border: 1px solid #ccc;
      }

      .form-info {
        margin: 10px 0 0;
        font-size: 14px;
        line-height: 1.4em;

        i {
          margin-right: 5px;
        }
      }
    }
  }
}

.page-template-template-landing-page-full,
.page-template-template-landing-page {

  header.banner {
    position: relative;

    .inner-container {
      width: 850px;
      text-align: center;
    }

    .container {
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      padding-top: 25px !important;
      padding-bottom: 20px !important;
    }

    .col-md-3 {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
    }

    .brand {
      margin: 0;
      display: block;

      a {
        background-image: url(../images/pixelter-logo-color.png);
        margin: 0 auto;
      }

      & + p {
        margin: 4px 0 0;
        font-size: 13px;
      }
    }

    .main-nav-wrap {
      display: none;
    }
  }

  .landing-page-title {
    padding-top: 50px;

    h1 {
      font-weight: bold;
      line-height: 1.2em;
    }
  }

  .contact-content {
    .inner-container {
      padding-top: 40px;
    }

    .signature {
      margin-top: 30px;
      font-size: 20px;
      line-height: 1.2em;
      margin-bottom: 25px;

      .name {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 3px;
        padding-top: 0 !important;
      }

      .user-info {
        float: left;
        font-size: 13px;
        line-height: 1.3em;
        font-weight: normal;


      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      img {
        border-radius: 100%;
        margin-right: 15px;
        float: left;
      }

      font-weight: bold;

      span.name {
        display: block;
        padding-top: 11px;
      }

      span.job {
        display: block;
        font-weight: 300;
        font-size: 15px;
        font-style: italic;
      }
    }

    .check-list {
      clear: both;

      li {
        margin-bottom: 14px;
        padding-top: 4px;
      }
    }

    .ebook {
      box-shadow: 2px -2px 0 0 #fff,
                  3px -3px 0 0 #ccc,
                  5px -5px 0 0 #fff,
                  6px -6px 0 0 #ccc,
                  8px -8px 0 0 #fff,
                  9px -9px 0 0 #ccc;
      margin-right: 30px;
      margin-top: 12px;
      margin-bottom: 30px;
    }

    ul {
      margin-top: 0px;
      margin-bottom: 30px;
    }

    h5 {
      margin-top: 30px;
      margin-bottom: 12px;
      font-size: 20px;
      clear: both;
    }
  }

  .contact-form-wrap h5 {
    margin: 0 0 15px;
    font-size: 21px;
  }

  footer.main-footer .container > .row {
    background: #fff;

    .social {
      display: none;
    }

    .copy {
      max-width: 100%;
      flex: 0 0 100%;
      text-align: center;

      p {
        margin-right: 0;
        float: none;
      }

      p, a {
        font-size: 11px;
      }

      nav {
        float: none;
        display: inline-block;

        a:hover {
          color: #373a3c;
        }
      }
    }
  }
}


// Case Stydies
// ========
.page-template-template-case-studies {
  .case-studies {
    .inner-container {
      padding-top: 0px;
      padding-bottom: 80px;
    }

    .cs-clientlist {
      margin-bottom: 60px;

      h5 {
        margin-bottom: 32px;
      }
    }

    .cs-detail {
      position: relative;
      margin-bottom: 60px;
      border: 1px solid #f9f9f9;
      box-shadow: 0 7px 42px rgba(0,0,0,.03);
      align-items: center;

      &:last-child {
        // border-bottom: none;
      }

      .cs-image {

        a {
          display: block;
          position: relative;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            // background: -webkit-linear-gradient(left,rgba(55,58,75,.79),rgba(121,146,59,.79));
            // background: linear-gradient(90deg,rgba(55,58,75,.79),rgba(121,146,59,.79));
            background: rgba(255,194,102,.82);
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 81px;
            height: 100%;
            background: url(../images/banner-side-angle-left.svg) no-repeat top left;
          }
        }

        &:not(.cs-image-right) {
          padding-left: 0;

          & + .cs-text {
            padding-right: 30px;
          }
        }

        &.cs-image-right {
          padding-right: 0;

          & + .cs-text {
            padding-left: 30px;
          }

          a:after {
            left: 0;
            right: auto;
            background: url(../images/banner-side-angle-right.svg) no-repeat bottom right;
          }
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .cs-logo-wrap {
        margin-bottom: 12px;
        display: block;
      }

      .cs-text {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      h2 {
        margin-bottom: 10px;

        a {
          color: #373a3c;
          line-height: 1.2em;
          font-weight: bold;
          font-size: 23px;
          display: block;

          &:hover {
            color: $brand-primary;
            text-decoration: none;
          }
        }
      }

      .improvement {
        position: absolute;
        top: 2px;
        right: 15px;
        color: $brand-primary;
        font-size: 20px;

        i {
          width: 30px;
          height: 30px;
          line-height: 28px;
          border: 1px solid $brand-primary;
          text-align: center;
          margin-right: 2px;
          font-size: 15px;
          @include border-radius(100%);
        }
      }

      a.btn {
        display: block;
        float: left;
        clear: both;
        font-size: 14px;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .cs-text {
        ul {
          list-style: none;
          margin: 0 0 20px;
          padding: 0;
        }

        li {
          font-size: 14px;
          padding-left: 20px;
          position: relative;
          margin-bottom: 4px;

          &:before {
            content: "\f067";
            font: normal normal normal 14px/1 FontAwesome;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 4px;
            color: $brand-primary;
          }
        }
      }
    }
  }
}// Case Stydies

// Agencies page
.page-template-template-agency {
  .page-header > .row {
    margin-bottom: 35px;
    position: relative;
    padding-bottom: 0;
    padding-top: 0;

    .col-md-12 {
      background: url(../images/agency-banner-6.jpg) no-repeat right -30px bottom;
      background-size: 980px;
      padding-bottom: 200px;
      padding-top: 190px;
    }

    .partnership {
      padding: 10px 25px;
      display: inline-block;
      margin-bottom: 10px;
      color: #f08260;
      text-transform: uppercase;
      border: 1px solid #ffc266;
    }

    h1 {
      font-size: 65px;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 0;
      color: #373a3c;
    }

    p {
      font-size: 20px;
      line-height: 1.4em;
      margin-bottom: 40px;
      margin: 0 0 5px;
      color: #111;
      padding: 18px 0;
      max-width: 545px;
      font-weight: 300;
    }

    .inner-container {
      width: 1350px;
      padding: 0 15px;
    }

    .btn {
      padding: .65rem 1.6rem;
    }
  }

  .main {
    .testimonials,
    .about-us,
    .value-prop,
    .cta-wrap {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .services {
      padding-bottom: 10px;

      .section-intro {
        margin-bottom: 50px;
      }

      .service-list {
        // .service-inner {
        //   max-width: 480px;
        //   padding-left: 25px;
        //   padding-top: 40px;
        //   padding-bottom: 40px;
        // }

        & > .row {
          margin-bottom: 65px;
          align-items: center;
        }

        .btn-small {
          color: #79923b;
          border-bottom: 1px dotted #79923b;
          text-decoration: none!important;
          position: relative;
        }

        ul {
          padding-left: 19px;
          margin-bottom: 22px;

          li {
            font-size: 15px;
          }
        }

        h3 {
          font-weight: bold;
          float: left;
          font-size: 29px;

          .icon {
            margin-right: 4px;
            margin-top: -5px;
          }
        }

        p {
          clear: both;
          font-size: 15px;
        }

        .conversion-audit {
          background: url(../images/conversion-audit.png) no-repeat;
          background-size: cover;

          & + div {
            border-bottom: 1px solid #eee;

            .service-inner {
              padding-top: 25px;
            }
          }
        }

        .conversion-boost {
          background: url(../images/conversion-boost.png) no-repeat center right;
          background-size: cover;

          & + div {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }

    .cta-wrap {
      .section-intro {
        margin-bottom: 20px;
      }

      .calendly-inline-widget {
        box-shadow: 0 4px 42px rgba(0,0,0,.03);
      }

      .contact-form-wrap {
        form {
          padding: 30px 30px;
          border: 1px solid #dadada;
          background: #e8e8e8;

          h4 {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 13px;
          }
        }

        .custom-dropdown {
          width: 100%;
          height: 40px;
        }

        input[type=text], input[type=email] {
          border: 1px solid #ccc;
        }

        .form-info {
          margin: 10px 0 0;
          font-size: 14px;
          line-height: 1.4em;

          i {
            margin-right: 5px;
          }
        }
      }

      .contact-info {
        padding-top: 30px;
        margin-bottom: 45px;

        h5 {
          margin-bottom: 30px;
          font-weight: bold;
        }

        p {
          position: relative;
          padding-left: 55px;
          margin-bottom: 40px;

          a {
            color: #373a3c;

            &:hover {
              color: #9fbb58;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          i {
            position: absolute;
            left: 0;
            top: -8px;
            color: #9fbb58;
            width: 40px;
            height: 40px;
            border: 1px solid #9fbb58;
            border-radius: 100%;
            text-align: center;
            line-height: 39px;
          }
        }
      }

      .contact-testimonial {

        h5 {
          margin-bottom: 13px;
        }
        blockquote {
          padding: 22px;
          padding-left: 47px;
          border-radius: 5px;
          font-size: 15px;
          line-height: 22px;
          font-style: italic;
          border: 1px solid #f7f4f4;
          box-shadow: 0 4px 42px rgba(0,0,0,.03);
          background: #fff;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 12px solid #fff;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            position: absolute;
            bottom: -12px;
            left: 24px;
          }

          &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 12px solid #e8e8e8;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            position: absolute;
            bottom: -13px;
            left: 24px;
          }

          i {
            position: absolute;
            left: 14px;
            top: 22px;
            font-size: 24px;
            // color: #e4e4e4;
            color: #9fbb58;
          }

          & + p {
            font-size: 15px;
            color: #848484;

            span {
              // font-weight: bold;
              color: #111;
              display: block;
              padding-top: 10px;

              .clinet-li {
                color: #0076b5;
                margin-left: 4px;
                font-size: 17px;
                vertical-align: middle;
                margin-left: 3px;
              }
            }

            img {
              margin-right: 12px;
              border-radius: 100%;
              margin-left: 7px;
              float: left;
            }
          }
        }
      }
    }

    .section-intro {
      margin-bottom: 30px;

      h2 {
        font-size: 45px;
        font-weight: 100;
        margin-bottom: 20px;

        strong {
          display: block;
          font-weight: bold;
          text-transform: uppercase;
        }
      }


      p {
        font-weight: 300;
        color: #3e3e3e;
        font-size: 21px;
        line-height: 1.5em;

        strong {
          font-weight: bold;
        }
      }
    }

    .about-us {
      position: relative;
      min-height: 530px;
      padding-top: 50px;

      .about-us-content {
        p {
          font-size: 18px;
          clear: both;
        }
      }

      h2 {
        font-weight: 700;
        background: #FFC266;
        color: #fff;
        padding: 12px 20px;
        float: left;
        font-size: 35px;
        margin-bottom: 26px;
      }

      .big-founder-wrap {
        // position: absolute;
        // right: -15px;
        // top: 50px;
        height: 590px;
        background: url(../images/john-lg-3.jpg) no-repeat center top;
        background-size: cover;

        .founder-info {
          position: absolute;
          bottom: 15px;
          right: 15px;

          .founder-links {
            float: right;
            clear: both;
            margin-top: 5px;

            &:after {
              content: "";
              display: block;
              clear: both;
            }

            a {
              width: 30px;
              height: 30px;
              background: #fff;
              display: block;
              text-align: center;
              line-height: 30px;
              border-radius: 100%;
              float: right;
              margin-left: 3px;
              color: #FFC266;
            }
          }

          .name {
            background: #FFC266;
            color: #fff;
            padding: 5px 15px;
            display: block;
            float: right;
            clear: both;
            font-size: 21px;
            font-weight: bold;
          }

          .details {
            background: #fff;
            padding: 5px 15px;
            display: block;
            float: left;
            clear: both;
            float: right;
            clear: both;
            font-size: 15px;
          }
        }
      }
    }

    .value-prop {
      // padding-bottom: 45px;

      .section-intro {
        p {
          font-size: 22px;
        }
      }

      .vp-bg {
        position: relative;

        img {
          max-width: none;
          position: absolute;
          right: 30px;
          top: 60px;
          width: 500px;
        }
      }

      .vp-list {
        .vp-box {
          margin-bottom: 20px;
          border: 1px solid #f7f4f4;
          box-shadow: 0 4px 42px rgba(0,0,0,.05);
          padding: 25px 25px 25px;
        }
        h3 {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 6px;
        }

        p {
          font-size: 15px;
          margin-bottom: 0;
        }

        img {
          margin-bottom: 20px;
        }
      }
    }
    .testimonials {
      .col-lg-4 {
        margin-bottom: 40px;
      }

      .section-intro {
        margin-bottom: 24px;
      }

      .testimonial-list {
        & > div {
          margin-bottom: 25px;
        }
      }

      h5 {
        margin-bottom: 15px;
        font-size: 14px;
        margin-top: 30px;
      }

      .slick-arrow {
        position: absolute;
        top: calc(50% - 75px);
        z-index: 5;
        display: block;
        text-indent: -9999px;
        border: none;
        width: 50px;
        height: 50px;
        outline: none;

        &.slick-prev {
          left: 0;
          background: url(../images/arrow-prev.svg) no-repeat center center;
          background-size: 25px;
          left: -90px;
        }

        &.slick-next {
          right: 0;
          background: url(../images/arrow-next.svg) no-repeat center center;
          background-size: 25px;
          right: -90px;
        }
      }

      .slick-dots {
        display: block;
        list-style: none;
        margin: 0;
        padding: 5px 0 0;
        text-align: center;
        background: #fff;

        li {
          display: inline-block;
          margin: 0 2px;

          &:only-child {
            display: none;
          }

          &.slick-active button {
            background: #FFC266;
          }

          button {
            width: .625rem;
            height: .625rem;
            border: none;
            display: block;
            text-indent: -9999px;
            border-radius: 100%;
            padding: 0;
            background: #eee;
          }
        }
      }

      blockquote {
        padding: 15px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-style: italic;
        position: relative;
        border: 1px solid #f7f4f4;
        box-shadow: 0 4px 42px rgba(0,0,0,.03);
        background: #fff;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid #fff;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          bottom: -12px;
          left: 15px;
        }

        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 12px solid #e3e0e0;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          bottom: -13px;
          left: 15px;
        }

        i {
          position: absolute;
          left: 14px;
          top: 15px;
          font-size: 24px;
          // color: #e4e4e4;
          color: #9fbb58;
          display: none;
        }

        & + p {
          font-size: 13px;
          color: #848484;
          margin: 0;


          .clinet-li {
            color: #0076b5;
            margin-left: 4px;
            font-size: 17px;
            vertical-align: middle;
            margin-left: 3px;
          }


          span {
            // font-weight: bold;
            color: #111;
            display: block;
          }

          .name {
            font-weight: bold;
            padding-top: 14px;
            display: block;
            line-height: 1.1em;
          }

          .title {
            font-size: 12px;
          }

          img {
            margin-right: 8px;
            border-radius: 100%;
            width: 60px;
            float: left;
          }
        }
      }
    }
  }
}

// New Case Study
.new-case-study-layout {
  .page-header > .row {
      padding-bottom: 75px !important;
  }

  .case-study-content .content-section {
    .case-study-logo {
      img {
        margin-bottom: 15px;
      }

      p {
        font-size: 15px !important;

        a {
          color: #FFC266;
        }
      }
    }
  }

  .case-study-content .content-section .solution-details  {
    padding-top: 30px;

    h3 {
      font-size: 28px !important;
      padding: 12px 20px !important;
      clear: both;
    }

    h4.small-headline {
      font-size: 19px;
      font-weight: normal;
      background: #3C3C3C;
      padding: 12px 20px;
      float: left;
      clear: both;
      color: #fff;
      margin-bottom: 0;
    }

    h2 {
      font-size: 35px;
      font-weight: 100;
      margin-bottom: 35px;
      text-align: center;

      strong {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .case-study-content .content-section .services-list-wrap {
    box-shadow: 0 0 30px rgba(0,0,0,.09);
    margin-bottom: 65px;

    h5 {
      text-align: center;
      background: #79923b;
      color: #fff;
      padding: 12px 0;
      margin: 0;
    }

    ul {
      &:after {
        content: "";
        display: block;
        clear: both;
      }

      margin: 0;
      padding: 0;
      max-width: none !important;

      li {
        float: left;
        width: 50%;
        list-style: none;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        padding: 20px 20px;
        margin: 0;
        font-size: 16px;

        &:nth-child(2n+2) {
          border-right: none;
        }

        i {
          color: #FF2950;
          margin-right: 7px;
        }
      }
    }
  }

  .case-study-testimonial-wrap {
    align-items: center;
  }

  .case-study-logo {
    text-align: center;
    margin-bottom: 30px;
  }

  .results-wrap-inner {

    h5 {
      border: 1px solid #eee;
      margin: 0 !important;
      border-bottom: none;
      text-align: center;
      padding: 14px 0;
      background: #eee;
      position: relative;
      font-size: 19px !important;

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        position: absolute;
        left: 50%;
        border-top: 12px solid #eee;
        bottom: -12px;
        margin-left: -18px;
      }
    }

    h5 + .data-point {
      // margin-top: 12px;
    }

    .data-point {
      border: 1px solid #eee;
      padding-top: 35px;
      padding-bottom: 35px;
      border-bottom: none;
      text-align: center;

      &:last-child {
        border-bottom: 1px solid #eee;
      }

      strong {
        color: #8bab3e;
        display: block;
        font-size: 35px;
        line-height: 1em;
      }

      span {
        font-weight: 300;
      }

      &.first {
        strong {
          color: #f6c475;
        }
      }

      &.second {
        strong {
          color: #f6c475;
        }
      }

      &.third {
        strong {
          color: #f6c475;
        }
      }
    }
  }

  .page-header h1 {
      // margin-bottom: 0 !important;
      color: #fff;
      // font-size: 60px;
      // background: #79923b;
      // padding: 20px 25px;
      // display: inline-block;
  }

  .page-header p {
    // background: #8bab3e;
    // padding: 20px 25px;
    color: #fff;
    // display: inline-block;
    clear: both;
    font-weight: normal;
    text-transform: capitalize;
    // font-size: 22px;
  }

  .page-header .col-lg-8 {
    left: 0;
    text-align: left !important;
  }

  .content-section {
    h5 {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 6px;
    }

    .divider-wrap {
      hr {
        margin: 45px 0 !important;
      }
    }

    .lg-testimonial-logo {
      text-align: center;

      img {
        margin-bottom: 5px !important;
      }

      .testimonial-name {
        margin-top: 7px;
        font-weight: bold;

        .clinet-li {
          color: #0076b5;
          margin-left: 4px !important;
          font-size: 18px;
          vertical-align: middle;
          margin-left: 3px;
        }
      }

      .testimonial-name,
      .testimonial-info {
        font-size: 13px;
        line-height: 1.2em;
      }
    }

    .lg-testimonial-text {
      p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
        position: relative;
        padding-left: 34px;
        color: #444;

        i {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 25px;
          color: #FFC266;
        }
      }
    }

    p {
      font-size: 16px !important;
    }

    p + h5 {
      margin-top: 40px;
    }
  }

  &.kohepets {
    .main .page-header>.row {
      background: url(../images/kp_banner.jpg) no-repeat top center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.chapul {
    .main .page-header>.row {
      background: url(../images/cs-chapul-banner.jpg) no-repeat center center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.entomofarms {
    .main .page-header>.row {
      background: url(../images/entomofarms-banner.jpg) no-repeat center center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.biconi {
    .main .page-header>.row {
      background: url(../images/biconi-banner.jpg) no-repeat center center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.simplyhamper {
    .main .page-header>.row {
      background: url(../images/simply-hamper-banner.jpg) no-repeat center center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.mymambaby {
    .main .page-header>.row {
      background: url(../images/mam-banner.jpg) no-repeat center center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.snuz {
    .main .page-header>.row {
      background: url(../images/snuz-banner.jpg) no-repeat center center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.watches2u {
    .main .page-header>.row {
      background: url(../images/watch-banner.jpg) no-repeat top center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }

  &.eternityweddingbands {
    .main .page-header>.row {
      background: url(../images/ewb-banner.jpg) no-repeat top center;
      background-size: cover;
      margin-bottom: 55px;
    }
  }
  //
  // &.conversion-boost {
  //   .main .page-header>.row {
  //     background: url(../images/boost-header.png) no-repeat center center;
  //     background-size: cover;
  //     margin-bottom: 55px;
  //   }
  // }
}

// Checkout
// =========
.woocommerce-checkout {
  .main .page-header>.row {
    padding-top: 110px;
  }

  .woocommerce-info {
    display: none;
  }

  .woocommerce-message .button {
    display: none;
  }

  #payment .payment_method_paypal .about_paypal {
    margin-left: 5px;
  }

  #payment ul.payment_methods li img {
    padding: 0 5px;
    background: #fff;
  }

  form .form-row .required {
    text-decoration: none;
  }

  .main .main-content-wrap {
    padding-top: 0;

    #billing_state_field {
      margin-right: 22px;
    }

    .place-order .button {
      background: #9fbb58;
      font-size: 18px;
      padding: 14px 32px;
      width: 100%;
    }

    tr.cart-subtotal {
      display: none;
    }

    table.shop_table td,
    table.shop_table th {
      padding: 13px 12px;
    }
  }

  .main-nav-wrap {
    display: none;
  }

  .secured-wrap {
    text-align: right;

    p {
      margin: 0;
      line-height: 26px;
      font-size: 17px;
      margin-top: 3px;

      i {
        color: #9fbb58;
        margin-right: 6px;
      }
    }
  }

  .checkout-progress {
    .progress-step {
      width: 33.333%;
      float: left;
      position: relative;

      &:after,
      &:before {
        content: "";
        width: 50%;
        height: 2px;
        background: #d3ced2;
        position: absolute;
        right: 0;
        top: 21px;
      }

      &.checked:after,
      &.checked:before,
      &.active:before,
      &.active:after {
        background: #9fbb58;
      }

      &:before {
        content: "";
        right: auto;
        left: 0;
      }

      &:first-child:before {
        display: none;
      }

      &:last-child:after {
        display: none;
      }

      &.checked,
      &.active {
        .circle-wrap {
          background: #9fbb58;
        }
      }

      .circle-wrap {
        display: block;
        width: 45px;
        height: 45px;
        line-height: 45px;
        background: #d3ced2;
        border-radius: 100%;
        margin: 0 auto 4px;
        color: #fff;
        font-size: 18px;
        position: relative;
        z-index: 2;
      }

      .text-wrap {
        font-size: 14px;
      }

      &.active {
        .text-wrap {
          font-weight: bold;
        }
      }
    }
  }
}

// Case Stydy
// ========
.single-case-study {
  .page-header>.row {
      padding-bottom: 15px;

      h1 {
        margin-bottom: 5px;
      }
  }

  .case-study-content {
    .content-section {
      padding-top: 0px;
      padding-bottom: 0;


      .testimonial-box {
        padding: 30px;
        border: 1px solid #dadada;
        background: #fbfbfb;
        margin: 35px 0 35px;

        blockquote {
          font-size: 17px;
          font-style: italic;
          padding-left: 30px;
          position: relative;
          margin-bottom: 30px;

          i {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 18px;
            color: #9fbb58;
          }
        }

        h5 {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 0;

          span {
            font-weight: normal;
          }

          img {
            margin: 0;
            margin-right: 10px;
            @include border-radius(100%);
          }
        }
      }

      p + img {
        margin-top: 30px;
      }

      h4 {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 12px;
        padding-top: 10px;
      }

      img {
        margin-bottom: 30px;
      }

      iframe {
        margin: 0 auto 30px;
        display: block;
      }

      p {
        clear: both;
      }

      p, li {
        font-size: 17px;
      }

      ul {
        max-width: 550px;
        margin-bottom: 1rem;

        li {
          margin-bottom: 6px;
        }
      }

      hr {
        border-top: 1px solid #e8e8e8;
        margin: 60px 0;
      }

      h3 {
        margin-bottom: 15px;
        font-size: 37px;
        font-weight: bold;
        background: #FFC266;
        padding: 14px 20px;
        color: #fff;
        float: left;
      }

      .section-intro {
        h2 {
          margin-bottom: 0;
        }
      }

      .check-list {
        margin-top: 30px;
      }

      .plus-list {
        margin-bottom: 25px;
      }
    }

    .detail-section {
      p {
        font-size: 14px;
      }
    }

    .testimonial-wrap {
      padding: 30px;
      background: #ebeff0;

      blockquote {
        font-size: 15px;
        font-style: italic;
        padding-left: 30px;
        position: relative;
        margin-bottom: 30px;

        i {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 18px;
        }
      }

      h5 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0;

        span {
          font-weight: normal;
        }

        img {
          margin-right: 10px;
          @include border-radius(100%);
        }
      }
    }
  }

  .bottom-cta {
    padding-top: 40px;
    padding-bottom: 90px;
  }
} // Case Stydy

// About
// ========
.page-template-template-about-v2 {
  .main .main-content-wrap {
    padding-top: 0px;

    .contact-form {
      form {
        padding: 30px 30px;
        border: 1px solid #f9f9f9;
        box-shadow: 0 4px 42px rgba(0,0,0,.03);

        h4 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 13px;
          text-align: left;
        }
      }

      .wpcf7-not-valid-tip {
        font-size: 13px;
        margin-top: 3px;
      }

      div.wpcf7-validation-errors {
        font-size: 14px;
      }

      input[type=text], input[type=email], textarea {
        border: 1px solid #ccc;
      }

      textarea {
        resize: none;
        height: 110px;
      }

      .custom-dropdown {
        width: 100%;
        height: 40px;
      }

      .form-info {
        margin: 10px 0 0;
        font-size: 14px;
        line-height: 1.4em;

        i {
          margin-right: 5px;
        }
      }
    }

    .intro-wrap p:last-child {
      margin-bottom: 0;
    }

    .small-founder-wrap,
    .big-founder-wrap {
      position: absolute;
      right: 0;
      top: -5px;
      height: 647px;
      background: url(../images/john-lg-3.jpg) no-repeat center top;
      background-size: cover;

      .founder-info {
        position: absolute;
        bottom: 15px;
        right: 15px;

        .founder-links {
          float: right;
          clear: both;
          margin-top: 5px;

          &:after {
            content: "";
            display: block;
            clear: both;
          }

          a {
            width: 30px;
            height: 30px;
            background: #fff;
            display: block;
            text-align: center;
            line-height: 30px;
            border-radius: 100%;
            float: right;
            margin-left: 3px;
            color: #FFC266;
          }
        }

        .name {
          background: #FFC266;
          color: #fff;
          padding: 5px 15px;
          display: block;
          float: right;
          clear: both;
          font-size: 21px;
          font-weight: bold;
        }

        .details {
          background: #fff;
          padding: 5px 15px;
          display: block;
          float: left;
          clear: both;
          float: right;
          clear: both;
          font-size: 15px;
        }
      }
    }

    .small-founder-wrap {
      position: relative;
      top: 0;
      left: 0;
      margin: 0 -25px 25px;
      height: 400px;
      background: url(../images/john-sm.jpg) no-repeat -30px 100%;
      background-size: cover;

      p.intro {
        padding: 20px 20px 0;

        span {
          display: block;
          background: #fff;
          font-size: 16px;
          line-height: 1.3;
          display: block;
          font-weight: normal;
          padding: 15px 15px 15px 35px;
          border-radius: 4px;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            position: absolute;
            left: 35px;
            bottom: -12px;
            border-top: 12px solid #fff;
          }

          i {
            position: absolute;
            top: 15px;
            left: 10px;
            color: #ffc266;
          }
        }
      }
    }



    .page-template-template-about-v2 .main .main-content-wrap .small-founder-wrap .founder-info .name {
      font-size: 18px;
    }

    .page-template-template-about-v2 .main .main-content-wrap .small-founder-wrap .founder-info .details {
      font-size: 14px;
    }

    .about-cta {
      position: relative;
      margin-top: 30px;

      .caption {
        position: absolute;
        top: 0;
        left: 0;
        padding: 30px;
        width: 360px;

        h3 {
          margin-top: 0;
          color: #fff;
          font-size: 35px;
          padding: 0;
          background: none;
        }

        p {
          color: #fff;
          font-size: 17px;
          line-height: 1.3em;
        }

        .btn-primary {
          background: #2e303d;
          border-color: #2e303d;

          &:hover {
            background: #222329;
            border-color: #222329;
          }
        }
      }
    }

    .proccess-image {
      margin-top: 10px;
    }

    .founder-image-wrap {
      position: relative;

      &:hover a {
        opacity: 1;
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(159, 187, 88, 0.78);
        color: #fff;
        text-align: center;
        border-radius: 100%;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;

        span {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -40px;
          margin-left: -40px;
          font-size: 35px;
          line-height: 80px;
          border: 1px solid #fff;
          border-radius: 100%;
        }
      }
    }

    .founder-img {
      border-radius: 100%;
    }

    .founder {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      padding-top: 10px;
      margin-bottom: 10px;

      span {
        font-weight: 300;
        display: block;
        font-size: 14px
      }
    }

    .contact-links {
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        list-style: none;
        display: inline-block;
        margin: 0 3px;

        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          border: 1px solid $brand-primary;
          color: $brand-primary;
          text-align: center;
          line-height: 40px;
          @include border-radius(100%);

          &:hover {
            background: $brand-primary;
            color: #fff;
          }
        }
      }
    }

    h4 {
      text-align: center;
      font-size: 16px;
    }

    .client-logos {
      margin-top: 65px;
      text-align: center;
    }

    .section-members {

      .team-member {
        margin-top: 30px;

        img {
          float: left;
          margin-right: 20px;
          @include border-radius(100%);
        }

        h5 {
          margin: 17px 0 2px;
          font-weight: 300;
          font-size: 18px;
        }

        p {
          font-weight: bold;
          margin: 0;
          line-height: 1.3em;
          font-size: 15px;
        }
      }
    }

    blockquote {
      padding: 15px;
      border-radius: 5px;
      font-size: 14px;
      line-height: 21px;
      font-style: italic;
      border: 1px solid #e8e8e8;
      position: relative;
      background: #fbfbfb;

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid #fbfbfb;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        position: absolute;
        bottom: -12px;
        left: 15px;
      }

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid #e8e8e8;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        position: absolute;
        bottom: -13px;
        left: 15px;
      }

      i {
        position: absolute;
        left: 14px;
        top: 15px;
        font-size: 24px;
        // color: #e4e4e4;
        color: #9fbb58;
        display: none;
      }

      & + p {
        font-size: 13px;
        color: #848484;
        margin: 0;

        span {
          // font-weight: bold;
          color: #111;
          display: block;
        }

        .name {
          font-weight: bold;
          padding-top: 14px;

          .clinet-li {
            color: #0076b5;
            margin-left: 4px;
            font-size: 17px;
            vertical-align: middle;
            margin-left: 3px;
          }
        }

        .job {
          font-size: 12px;
        }

        img {
          margin-right: 8px;
          border-radius: 100%;
          width: 60px;
          float: left;
        }
      }
    }

    .testimonials-wrap {
      margin-top: 30px;
    }

    .icon-list {
      list-style: none;
      padding: 0;
      margin-top: 30px;

      li {
        position: relative;
        padding-left: 60px;
        margin-bottom: 20px;
      }

      span.number {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #9FBB58;
        border-radius: 100%;
        color: #fff;
        font-size: 15px;
      }
    }

    p.intro {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.3em;
    }

    p {
      font-size: 18px;
      margin-bottom: 18px;
      clear: both;
    }

    li {
      font-size: 17px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 27px;
      margin-top: 85px;
      margin-bottom: 12px;
      font-weight: bold;
      background: #f6c475;
      padding: 14px 20px;
      float: left;
      clear: both;
      color: #fff;
    }

    h5 {
      margin-top: 85px;
      font-size: 21px;
      // font-weight: 300;
      background: #3C3C3C;
      color: #fff;
      padding: 12px 20px;
      float: left;
      clear: both;
      color: #fff;
      margin-bottom: 0;
    }

    h5+h3 {
      margin-top: 0;
    }
  }
}

.page-template-template-about {
  .about-content {
    .content-section {
      padding-top: 0px;
      padding-bottom: 80px;
      border-bottom: 1px solid #e8e8e8;
    }

    .top-section {

      .section-intro {
        margin-bottom: 30px;
      }

      .image-wrap {
        padding-top: 40px;
      }

      .icon-list {
        list-style: none;
        padding: 0;
        margin-top: 30px;

        li {
          position: relative;
          padding-left: 60px;
          margin-bottom: 20px;
        }

        span.number {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #9FBB58;
          border-radius: 100%;
          color: #fff;
        }
      }

      .client-logos-wrap {
        margin-top: 70px;

        h3 {
          text-align: center;
          font-size: 23px;
          margin-bottom: 17px;
          font-weight: bold;
        }
      }

      .client-logos {
        text-align: center;
        width: 100%;

        .col {
          width: 20%;
          float: left;
          line-height: 125px;

          a {
            transition: all .3s ease-in-out;
          }

          a:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .team-section {

      h2 {
        margin-bottom: 18px;
        font-weight: bold;
      }

      .section-intro {
        margin-bottom: 50px;
      }

      .section-content {
        margin-bottom: 80px;

        h3 {
          margin-bottom: 4px;
        }

        h4 {
          font-weight: 300;
          font-size: 17px;
          margin-bottom: 16px;
        }

        p {
          font-size: 14px;
          font-style: italic;
          margin-bottom: 18px;
          position: relative;
        }

        i {
          font-size: 19px;
        }

        .fa-quote-left {
          margin-right: 10px;
        }

        .fa-quote-right {
          margin-left: 10px;
        }

        .image-wrap {
          img {
            @include border-radius(100%);
          }
        }

        .btn {
          float: left;
          font-size: 14px;
        }

        .contact-links {
          margin: 0 20px 0 0;
          padding: 0;
          float: left;

          li {
            list-style: none;
            display: inline-block;
            margin-right: 5px;

            a {
              display: inline-block;
              width: 40px;
              height: 40px;
              border: 1px solid $brand-primary;
              color: $brand-primary;
              text-align: center;
              line-height: 40px;
              @include border-radius(100%);

              &:hover {
                background: $brand-primary;
                color: #fff;
              }
            }
          }
        }
      }

      .section-certificates {
        width: 100%;
        padding-top: 50px;
      }

      .section-members {
        width: 100%;

        .team-member {
          margin-bottom: 30px;

          img {
            float: left;
            margin-right: 20px;
            @include border-radius(100%);
          }

          h5 {
            margin: 17px 0 0;
            font-weight: 300;
          }

          p {
            font-weight: bold;
            margin: 0;
            line-height: 1.3em;
          }
        }
      }
    }

    .bottom-section {
      border-bottom: none;

      h2 {
        font-weight: bold;
      }

      .case-study {
        border: 1px solid #e8e8e8;
        padding: 20px;

        &:not(:last-child) {
          border-right-color: #fff;
        }

        .image-link {
          margin-bottom: 15px;
          display: block;
        }

        h5 {
          font-size: 15px;
          font-weight: bold;
          float: left;
          margin-bottom: 20px;

          span {
            font-weight: 100;
          }

          img {
            margin-right: 10px;
            @include border-radius(100%);
          }
        }

        blockquote {
          font-size: 14px;
          font-style: italic;

          i {
            margin-right: 8px;
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    .bottom-cta {
      text-align: center;
      padding-bottom: 80px;

      .row {
        min-width: 100%;
      }

      h3 {
        margin-bottom: 22px;
      }

      .btn {
        margin-right: 20px;
      }

      i {
        margin-right: 5px;
      }

      .inner-container {
        border-top: 1px solid #e8e8e8;
        padding-top: 50px;
      }
    }
  }
} // About


// Services
// ========
.page-template-template-services-v2,
.page-template-template-services {
  .page-header {
    margin-bottom: 65px;

    .col-md-10 {
      left: 0;
    }

    h1 {
      color: #fff;
      background: #79923b;
      padding: 20px 25px;
      float: left;
      font-weight: bold;
      margin: 0;
    }

    p {
      background: #fff;
      color: #111;
      clear: both;
      text-align: left;
      padding: 20px 25px;
      font-size: 20px;
    }

    & > .row {
      background: url(../images/services-header.png) no-repeat;
      background-size: cover;
      padding-bottom: 90px;
    }
    .our-results {
      margin: 22px 0 0;
      padding: 0;

      li {
        display: inline-block;
        list-style: none;
        border: 1px solid #fff;
        @include border-radius(100%);
        width: 100px;
        height: 100px;
        padding-top: 34px;
        margin: 0 10px;
        font-size: 14px;
        font-weight: 100;
        line-height: 1.2em;
        color: #fff;
        text-align: center;

        span {
          font-size: 24px;
          font-weight: normal;
          display: block;
          margin-bottom: 3px;
        }
      }
    }
  }

  .section-plans {
    .plan-wrap {
      width: 408px;
      max-width: 100%;
      height: 395px;
      padding-top: 110px;
    }

    p {
      clear: both;
      background: #fff;
      padding: 12px 20px 14px;
      line-height: 1.45em;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      span {
        display: block;
        font-weight: 300;

        strong {
          font-weight: bold;
        }
      }

      a {
        display: block;
        float: left;
        margin-top: 8px;
        color: #79923b;
        border-bottom: 1px dotted #79923b;
        text-decoration: none !important;
      }
    }

    h3 {
      background: #79923b;
      color: #fff;
      padding: 10px 20px;
      font-weight: bold;
      float: left;
      margin: 0;
    }

    .conversion-audit {
      background: url(../images/conversion-audit.png) no-repeat;
      background-size: cover;

      .plan-wrap {
        float: right;
      }
    }

    .conversion-boost {
      background: url(../images/conversion-boost.png) no-repeat;
      background-size: cover;
    }
  }

  .service-content {
    .content-section {
      padding-top: 90px;
      padding-bottom: 80px;
      border-bottom: 1px solid #e8e8e8;
    }

    .top-section {
      .content-graph {
        margin-bottom: 40px;

        img {
          max-width: 100%;
        }
      }

      .content-info {
        .step-1,
        .step-2 {
          padding-top: 25px;
        }

        .step-1 {
          border-top: 8px solid #2e303d;
        }

        .step-2 {
          border-top: 8px solid $brand-primary;
        }

        h3 {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        h4 {
          font-weight: 100;
          font-size: 19px;
          margin-bottom: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .section-intro {
      margin-bottom: 20px;
    }
  }

  .section-2,
  .section-3,
  .section-4 {
    .section-intro {
      h2 {
        margin-bottom: 18px;
        font-weight: bold;
      }
      margin-bottom: 50px;
    }
  }

  .section-4 {
    .section-content {
      text-align: center;
    }

    .section-intro {
      margin-bottom: 30px;
    }
  }

  .section-2 {
    .content-wrap {
      padding-left: 0;
    }

    .image-wrap {
      padding-top: 60px;
    }

    .point {
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 20px;

      &.point-1 {
        border-left: 7px solid #8BAB3E;
      }

      &.point-2 {
        border-left: 7px solid #9FBB58;
      }

      &.point-3 {
        border-left: 7px solid #B2C979;
      }

      &.point-4 {
        border-left: 7px solid #E4ECD0;
      }

      h3 {
        font-size: 21px;
        font-weight: bold;
        background: #79923b;
        float: left;
        color: #fff;
        padding: 10px 20px;
        margin-bottom: 12px;
        clear: both;
      }

      h4 {
        background: #A2C058;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        padding: 7px 20px;
        margin: 0;
        float: left;
      }

      p {
        font-size: 14px;
        margin: 0;
        clear: both;
      }
    }
  }

  .section-3 {
    .delivery {
      margin-bottom: 30px;
      position: relative;
      // padding-left: 60px;

      img {
        margin-bottom: 10px;
        height: 35px;
      }

      i {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        width: 40px;
        height: 40px;
        background: $brand-primary;
        @include border-radius(4px);
        text-align: center;
        line-height: 40px;
      }

      h3 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .bottom-section {
    h5 {
      font-size: 19px;
    }

    .cta-banner {
      margin-top: 30px;
    }
  }

  .quiz-section {
    .section-intro {
      margin-bottom: 40px;

      h2 {
        margin-bottom: 18px;
      }
    }

    .content-wrap {
      border: 1px solid #e8e8e8;
      padding-top: 40px;
      padding-bottom: 40px;
      background: #ebeff0;

      &.finished {
        .quiz-questions {
          display: none;
        }

        .quiz-results {
          display: block;
        }

        .quiz-steps {
          li.results {
            background: $brand-primary;
            color: #fff;
          }
        }
      }

      .quiz-steps {
        text-align: center;

        ul {
          padding: 0;
          margin: 0 0 20px;
          display: inline-block;
          @include clearfix;
        }

        li {
          float: left;
          list-style: none;
          margin: 0 5px;
        }



        .step {
          position: relative;

          &.active a {
            background: $brand-primary;
            color: #fff;

            &:hover {
              color: #fff;
            }
          }

          a {
            width: 30px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            border: 1px solid $brand-primary;
            font-weight: normal;
            @include border-radius(100%);
            font-size: 14px;
            background: #fff;

            &:hover {
              text-decoration: none;
              color: $brand-primary;
            }
          }

          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 11px;
            background: $brand-primary;
            position: absolute;
            right: -10px;
            top: 50%
          }
        }

        .results {
          line-height: 30px;
          color: $brand-primary;
          font-weight: normal;
          border: 1px solid $brand-primary;
          @include border-radius(14px);
          font-size: 14px;
          padding: 0 10px;
          background: #fff;

          &.active {
            background: $brand-primary;
            color: #fff;
          }
        }
      }

      .quiz-results {
        display: none;
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;

        p {
          font-weight: 300;
          font-size: 14px;
        }

        form {
          max-width: 250px;
          margin: 0 auto;
          text-align: left;

          input[type=text], input[type=email] {
            border: 1px solid #ccc;
          }

          .form-info {
            margin-top: 8px;

            i {
              margin-right: 5px;
            }
          }
        }
      }

      .quiz-questions {
        text-align: center;

        .single-question {
          display: none;

          h4 {
            margin-bottom: 15px;
          }

          .invalid {
            label {
              color: red;
            }
          }

          .answers {
            .option-wrap {
              @include clearfix;
              display: inline-block;
            }

            p {
              margin: 0 0 5px;
              float: left;
              clear: both;

              label {
                font-style: italic;;
              }

              input {
                margin-right: 5px;
              }
            }

            .btn {
              clear: both;
              display: block;
              margin: 20px auto 0;
            }
          }

          &.active {
            display: block;
          }
        }
      }
    }
  }

  .case-study-section {
    border-bottom: none;

    h2 {
      font-weight: bold;
      margin-bottom: 18px;
    }



    .case-study {
      border: 1px solid #e8e8e8;
      padding: 20px;

      &:not(:last-child) {
        border-right-color: #fff;
      }

      .image-link {
        margin-bottom: 15px;
        display: block;
      }

      h5 {
        font-size: 15px;
        font-weight: bold;
        float: left;
        margin-bottom: 20px;

        span {
          font-weight: 100;
        }

        img {
          margin-right: 10px;
          @include border-radius(100%);
        }
      }

      blockquote {
        font-size: 14px;
        font-style: italic;

        i {
          margin-right: 8px;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .bottom-cta {
    text-align: center;
    padding-bottom: 80px;

    .row {
      min-width: 100%;
    }

    h3 {
      margin-bottom: 22px;
    }

    .btn {
      margin-right: 20px;
    }

    i {
      margin-right: 5px;
    }

    .inner-container {
      padding-top: 50px;
    }
  }
}// Services

// .page-template-template-services-v2 {
//   .section-2 {
//     .image-wrap {
//       padding-top: 25px;
//     }
//   }
// }

.page-template-template-services-v2 {
  .section-2 .image-wrap {
    padding-top: 0;
  }
  .main .page-header p strong {
    font-weight: bold;
  }
  .service-content .top-section .content-info p {
    font-size: 15px;
  }
  .cta-banner {
    margin-top: 40px;
  }
  .service-content .content-section.section-1 {
    padding-top: 20px;

    position: relative;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-top: 30px solid #fff;
      position: absolute;
      bottom: -30px;
      left: 50%;
      margin-left: -50px;
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-top: 30px solid #e8e8e8;
      position: absolute;
      bottom: -31px;
      left: 50%;
      margin-left: -50px;
    }

    .section-item {

      img {
        width: 40px;
        margin-bottom: 12px;
      }

      h3 {
        font-weight: bold;
        font-size: 18px;
      }

      &.last {
        margin-bottom: 0;
      }
    }
  }

  .content-section .section-intro h2 strong {
    text-transform: none;
  }

  // .main .page-header p {
  //   font-size: 22px;
  // }

  .section-2 .top-point {
    margin-bottom: 80px;
    padding-bottom: 50px;
    border-bottom: 1px solid #eee;
  }

  .section-2 .cro-point img {
    margin-top: 85px;
  }

  .section-2 .personal-point img {
    margin-top: 55px;
  }

  .section-2 .data-point img {
    margin-top: 85px;
  }

  .section-2 .point {
    padding: 0;
    margin-bottom: 0;

    h3 {
      font-size: 23px;
    }

    p {
      margin-bottom: 14px;
      font-size: 15px;
    }
  }
}



// Thank you page
// ========
.page-template-template-thank-you {
  .page-header {
    h1 {
      line-height: 58px;
      margin-bottom: 22px;
    }

    .btn {
      margin-bottom: 30px;
    }
  }
  .thank-you-content {
    padding-top: 90px;
    padding-bottom: 80px;

    h2 {
      text-align: center;
      font-weight: 100;
      margin-bottom: 40px;
      font-size: 25px;

      strong {
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }

    .blog-post-list {
      .blog-post {
        border: 1px solid #e8e8e8;
        padding: 20px;
        padding-bottom: 70px;

        h3 {
          font-size: 18px;
          line-height: 1.4em;
          font-weight: bold;

          a {
            color: #373a3c;
          }
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        .btn {
          position: absolute;
          bottom: 20px;
          left: 20px;
          font-size: 14px;
        }

        &:not(:last-child) {
          border-right-color: #fff;
        }

        .image-link {
          margin-bottom: 15px;
          display: block;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}


// 404 page
// ========

.error404 {
  .error-content {
    padding-top: 90px;
    padding-bottom: 80px;

    h2 {
      text-align: center;
      font-weight: 100;
      margin-bottom: 40px;
      font-size: 25px;

      strong {
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }

    .case-study {
      border: 1px solid #e8e8e8;
      padding: 20px;

      &:not(:last-child) {
        border-right-color: #fff;
      }

      .image-link {
        margin-bottom: 15px;
        display: block;
      }

      h5 {
        font-size: 15px;
        font-weight: bold;
        float: left;
        margin-bottom: 20px;

        span {
          font-weight: 100;
        }

        img {
          margin-right: 10px;
          @include border-radius(100%);
        }
      }

      blockquote {
        font-size: 14px;
        font-style: italic;

        i {
          margin-right: 8px;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}


// Responsive

@media (max-width: 1600px) {
  .page-template-template-agency .page-header>.row .col-md-12 {
    background-size: 840px;
  }
}

@media (max-width: 1400px) {
  .page-template-template-agency .page-header>.row .col-md-12 {
    background-size: 690px;
  }
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .hp-content .top-banner .inner-container {
      width: 100%;
  }
}

@media (min-width: 1000px) {
  .page-template-template-landing-page-full .inner-container .main-content .cta-top {
    display: none !important;
  }
}

@media (max-width: 1340px) {
  // .hp-content .top-banner h2 {
  //   font-size: 45px;
  //   line-height: 52px;
  // }

  .hp-content .top-banner p {
    font-size: 18px;
    line-height: 1.4em;
  }
}

@media (max-width: 1300px) {
  .page-template-template-about-v2 .main .main-content-wrap .big-founder-wrap {
    height: 500px;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap.portfolio-wrap-dark {
    background-position: -110px bottom;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap {
    background-position: right -110px bottom;
  }

  .page-template-template-agency .page-header>.row .col-md-12 {
    background-size: 570px;
    padding-bottom: 80px;
  }

  .main {
    overflow: hidden;
  }

  .page-template-template-agency .page-header>.row .col-md-12 {
    background-position: right -110px bottom;
  }
}

@media (max-width: 1140px) and (min-width: 1000px) {
  .hp-content .top-banner .banner-content .col-lg-7  {
    max-width: 70%;
    flex: 0 0 70%;
  }
}

@media (max-width: 1250px) {
  .hp-content .top-banner .banner-img {
    width: 480px;
    right: 0;
    top: 60px;
  } 
}

@media (max-width: 1100px) {
  .hp-content .top-banner .banner-img {
    right: -180px;
  }
}

@media (max-width: 1000px) {

  body.page-template.our-approach .header-bg {
    height: 130px;
  }

  .hp-content .hp-plans .conversion-audit .plan-wrap,
  .hp-content .hp-plans .shopify-development .plan-wrap,
  .hp-content .hp-plans .plan-wrap {
    width: 100%;
    padding: 50px 0 20px !important;
    height: auto;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wraw {
    background-position: right bottom;
  }

  .page-template-template-plan-wide.shopify-development .main .main-content-wrap .cro-stage {
    padding-bottom: 35px;
  }

  .page-template-template-plan-wide.shopify-development #price {
    margin-bottom: 25px;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap.last {
    margin-bottom: 50px;
  }

  .page-template-template-plan-wide.shopify-development .section-intro h2 {
    margin-bottom: 30px;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap .portfolio-content,
  .page-template-template-plan-wide.shopify-development .portfolio-wrap:not(.potfolio-wrap-dark) .portfolio-content {
    padding-top: 50px;
    min-height: auto;
    padding-bottom: 0;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap .portfolio-img,
  .page-template-template-plan.shopify-development .portfolio-wrap .portfolio-img {
    position: relative;
    left: 0 !important;
    right: 0 !important;
    top: 0;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

  .page-template-template-plan-wide .main .main-content-wrap .contact-form form, .page-template-template-plan .main .main-content-wrap .contact-form form {
    padding: 40px 30px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .divider-wrap, .page-template-template-plan .main .main-content-wrap .divider-wrap {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .page-template-template-agency .page-header>.row h1 {
    font-size: 38px;
    line-height: 41px;
    float: left;
  }

  .page-template-template-agency .main .services .service-list h3 {
    font-size: 25px;
  }

  .page-template-template-agency .main .testimonials .section-intro {
    margin-bottom: 10px;
  }


  .page-template-template-agency .small-founder-wrap {
    clear: both;
    position: relative;
    top: 0;
    left: 0;
    margin: 0 -25px 25px;
    height: 400px;
    background: url(../images/john-sm.jpg) no-repeat -30px 100%;
    background-size: cover;

    .founder-info {
      position: absolute;
      bottom: 15px;
      right: 15px;

      .founder-links {
        float: right;
        clear: both;
        margin-top: 5px;

        &:after {
          content: "";
          display: block;
          clear: both;
        }

        a {
          width: 30px;
          height: 30px;
          background: #fff;
          display: block;
          text-align: center;
          line-height: 30px;
          border-radius: 100%;
          float: right;
          margin-left: 3px;
        }
      }

      .name {
        background: #ffc266;
        color: #fff;
        padding: 5px 15px;
        display: block;
        float: right;
        clear: both;
        font-size: 21px;
        font-weight: bold;
      }

      .details {
        background: #fff;
        padding: 5px 15px;
        display: block;
        float: left;
        clear: both;
        float: right;
        clear: both;
        font-size: 15px;
        font-weight: 300;
      }
    }

    p.intro {
      padding: 20px 20px 0;

      span {
        display: block;
        background: #fff;
        font-size: 16px;
        line-height: 1.3;
        display: block;
        font-weight: normal;
        padding: 15px 15px 15px 35px;
        border-radius: 4px;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          position: absolute;
          left: 35px;
          bottom: -12px;
          border-top: 12px solid #fff;
        }

        i {
          position: absolute;
          top: 15px;
          left: 10px;
          color: #ffc266;
        }
      }
    }
  }

  .page-template-template-agency .main .about-us .about-us-content p {
    font-size: 16px;
  }

  .page-template-template-agency .main .services .section-intro {
    margin-bottom: 25px;
  }

  .page-template-template-agency .main {
    .about-us,
    .cta-wrap,
    .testimonials,
    .value-prop {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .page-template-template-agency .main .services .service-list p,
  .page-template-template-agency .main .services .service-list li {
    font-size: 15px;
  }

  .page-template-template-agency .main .about-us .big-founder-wrap {
    top: auto;
    height: 400px;
  }

  .page-template-template-agency .main .cta-wrap .contact-testimonial blockquote+p img {
    float: left;
  }

  .page-template-template-agency .main .cta-wrap .contact-testimonial blockquote+p span {
    display: block;
    padding-top: 12px;
  }

  .page-template-template-agency .main .cta-wrap .contact-info p {
    margin-bottom: 26px;
  }

  .page-template-template-agency .page-header>.row p {
    font-size: 17px;
    clear: both;
  }

  .page-template-template-agency .main .section-intro h2 {
    font-size: 38px;
  }

  .page-template-template-agency .main .value-prop .section-intro p {
    font-size: 19px;
  }

  .page-template-template-agency .main .section-intro {
    margin-bottom: 30px;
  }


  .page-template-template-landing-page.page-id-1691 .btn-primary {
    font-size: 14px;
    padding: .65rem 8px;
  }

  .page-template-template-about-v2 .main .main-content-wrap .big-founder-wrap {
    height: 367px;
  }

  .hp-content .top-banner>.row {
    background-position: left -110px top;
  }

  .hp-content .top-banner h2 {
    float: left;
  }

  .page-template-template-services-v2 .section-plans .conversion-audit,
  .page-template-template-services-v2 .section-plans .conversion-boost,
  .hp-content .hp-plans .conversion-audit,
  .hp-content .hp-plans .conversion-boost,
  .hp-content .hp-plans .shopify-development {
    // padding: 0 30px;
  }

  .hp-content .top-banner p {
    clear: both;
  }

  .page-template-template-landing-page.page-id-1691 .wpcf7-form p {
    position: relative;
  }

  .page-template-template-landing-page.page-id-1691 div.wpcf7 img.ajax-loader {
    position: absolute;
    right: 0;
    top: 13px;
  }

  header.banner .inner-container,
  .hp-content .top-banner .inner-container {
    width: 94%;
  }

  .hp-content .top-banner .inner-container {
    padding: 0;
  }

  .page-template-template-landing-page-full .inner-container .cta-wrap {
    text-align: center;
  }

  .page-template-template-landing-page-full .inner-container .main-content {
    padding-right: 15px;
  }

  .page-template-template-landing-page-full .inner-container iframe {
    width: 100%;
  }

  .page-template-template-landing-page-full .inner-container .main-content .cta-bottom {
    display: none !important;
  }

  .page-template-template-landing-page-full .inner-container .main-content .cta-wrap {
    margin-top: 0;
  }

  .page-template-template-landing-page-full .inner-container .cta-wrap.cta-top,
  .page-template-template-landing-page-full .inner-container .cta-wrap.cta-side {
    margin-top: 25px;
  }

  .page-template-template-landing-page-full .inner-container .special-offer {
    margin-bottom: 20px;
  }

  .woocommerce-checkout .secured-wrap {
    text-align: center;

    p {
      line-height: 15px;
      font-size: 14px;
      margin-top: 9px;
    }
  }

  .page-template-template-agency .page-header>.row .col-md-12 {
    padding-top: 20px;
    padding-bottom: 70px;
    background-size: 440px;
    overflow: hidden;
    background-position: right -140px bottom;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .hp-content .top-banner .banner-content {
    // padding-top: 120px;
    height: auto;
    // padding-bottom: 120px;
    padding-top: 110px;
    padding-bottom: 140px;
  }

  .page-template-template-case-studies .case-studies .cs-detail .cs-text {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .page-template-template-case-studies .case-studies .cs-detail {
    align-items: initial;
  }

  .page-template-template-case-studies .case-studies .cs-detail .cs-image a {
    position: absolute;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .page-template-template-case-studies .case-studies .cs-detail h2 a {
    font-size: 21px;
    display: block;
  }

  .hp-content .top-banner h2 {
    font-size: 55px;
    line-height: 55px;
  }

  .hp-content .case-study-section .case-study .image-link img {
    width: 240px;
    height: 100%;
    object-fit: cover;
  }

  .hp-content .case-study-section .case-study .image-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .hp-content .case-study-section .case-study .cs-logo-wrap {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .hp-content .case-study-section .case-study {
    padding: 50px 40px;
    padding-left: 290px;
  }

  .hp-content .case-study-section .case-study h5 {
    margin-top: 0;
  }

  .hp-content .case-study-section .case-study .image-link {
    margin-right: 35px;
  }

  .content-section .section-intro h2 {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .content-section .section-intro p {
    font-size: 18px;
    font-weight: 300;
  }

  header.banner .nav-primary li a {
    font-size: 17px;
    line-height: 40px;
    white-space: nowrap;
  }

  .hp-content .top-section .section-content .content-wrap {
    padding-top: 0;
  }

  .hp-content .top-section .section-content .image-wrap {
    padding-top: 10px;
  }

  .hp-content .middle-section {
    margin-top: 0;
  }

  .hp-content .middle-section > .row {
    padding-top: 90px;
  }

  .hp-content .bottom-section .single-testimonial .testimonial-image {
    padding: 15px 15px 0;
  }

  .hp-content .bottom-section .single-testimonial .testimonial-text {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hp-content .bottom-section .single-testimonial {
    border: none;
  }

  .hp-content .bottom-section .testimonials > .row > div {
    border: 1px solid #e8e8e8;

    &:first-child {
      border-right: none;
    }
  }

  footer.main-footer .copy p {
    margin-right: 25px;
  }

  .main .page-header > .row {
    padding-top: 100px;
    padding-bottom: 40px;
  }

  .page-template-template-services-v2 .section-2 .section-intro, .page-template-template-services-v2 .section-3 .section-intro,
  .page-template-template-services .section-2 .section-intro, .page-template-template-services .section-3 .section-intro {
    margin-bottom: 30px;
  }

  .page-template-template-services-v2 .service-content .section-intro,
  .page-template-template-services .service-content .section-intro {
    margin-bottom: 35px;
  }

  .page-template-template-services-v2 .case-study-section .case-study:last-child
  .page-template-template-services .case-study-section .case-study:last-child {
    display: none;
  }

  .page-template-template-services-v2 .case-study-section .case-study:nth-child(2),
  .page-template-template-services .case-study-section .case-study:nth-child(2) {
    border-right: 1px solid #e8e8e8;
  }

  .page-template-template-about .bottom-section .case-study:last-child {
    display: none;
  }

  .page-template-template-about .bottom-section .case-study:nth-child(2) {
    border-right: 1px solid #e8e8e8 !important;
  }

  .main .cta-banner .btn {
    font-size: 15px;
    padding: 0.65rem;
  }

  .main .cta-banner h3 {
    font-size: 22px;
  }

  .main .cta-banner p {
    font-size: 18px;
  }

  .page-template-template-about .about-content .top-section .client-logos .col {
    padding: 0 20px;
    line-height: 110px;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap .row>div {
    padding: 30px;
  }

  .pricing-page .pricing-wrap h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap p {
    font-size: 13px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details h2 {
    font-size: 40px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details h3 {
    font-size: 20px;
  }

  .page-template-template-landing-page .contact-form-wrap form {
    padding: 25px;
  }

  .page-template-template-landing-page .contact-form-wrap .form-info {
    font-size: 13px;
  }

  .page-template-template-landing-page .contact-content .ebook {
    max-width: 170px;
  }
  .page-template-template-contact .contact-content .contact-testimonial blockquote+p img,
  .page-template-template-landing-page .contact-content .contact-testimonial blockquote+p img,
  .hp-content .section-clients blockquote+p img {
    width: 54px;
  }
  .page-template-template-contact .contact-content .contact-testimonial blockquote+p,
  .page-template-template-landing-page .contact-content .contact-testimonial blockquote+p,
  .hp-content .section-clients blockquote+p {
    font-size: 13px;
  }

  body.page-template.our-approach .section-title .section-intro p {
    font-size: 17px;
    font-weight: 300;
    line-height: 1.4em;
  }

  body.page-template.our-approach .section-title .layer-list span {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    top: -1px;
  }

  body.page-template.our-approach .section-title .layer-list li {
    font-size: 15px;
    padding-left: 30px;
    margin-bottom: 19px;
  }

  body.page-template.our-approach .section-title .section-intro {
    width: auto;
    margin-bottom: 30px;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-text .examples li {
    padding: 8px 0;
  }
}


@media (max-width: 767px) {
  .hp-content .top-banner .banner-img {
    position: static;
    display: block;
    margin: 30px auto -100px;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-image {
    height: 200px;
    background-position: center;
    background-size: cover;
  }

  .pricing-page.page-template-template-pricing-v2 .services-wrap .audit-package .service-content h2 a img {
    display: none;
  }

  .pricing-page.page-template-template-pricing-v2 .services-wrap .audit-package .service-content h2 a {
    white-space: normal;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-image:after {
    width: 100%;
    height: 35px;
    top: auto;
    bottom: 0;
    background: url(../images/mobile-banner-overlay.svg) no-repeat 100% 100%;
    background-size: cover;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-text {
    padding: 15px 20px 40px 20px;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-text h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-text h4 {
    font-size: 17px;
    margin-bottom: 3px;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-text p {
    font-size: 15px;
  }

  body.page-template.our-approach .main-content .layer-list .layer-item .layer-text .examples li {
    padding: 5px 0;
    width: auto;
    float: none;
  }

  body.page-template.our-approach .section-title>.row>.col-md-12 {
    padding-top: 10px;
  }

  body.page-template.our-approach .section-title .section-intro h3 {
    font-size: 20px;
  }

  body.page-template.our-approach .section-title .section-intro h1 {
    font-size: 38px;
    margin-bottom: 15px;
    line-height: 1em;
  }

  body.page-template.our-approach .section-title .section-intro p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4em;
  }

  body.page-template.our-approach .section-title .section-intro {
    width: auto;
  }

  body.page-template.our-approach .section-title .section-content {
    width: auto;
    margin: 0 calc(-15px - 4%);
    background: url(../images/mobile-ball3.png) no-repeat left top;
    background-size: 145px;
  }

  body.page-template.our-approach .section-title .layer-img {
    display: none;
  }

  body.page-template.our-approach .section-title .layer-list-wrap {
    padding-right: 0;
    padding-left: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  body.page-template.our-approach .section-title .layer-list span {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    top: -1px;
  }

  body.page-template.our-approach .section-title .layer-list li {
    font-size: 14px;
    padding-left: 30px;
    margin-bottom: 15px;
  }

  body.page-template.our-approach .section-title {
    margin-bottom: 45px;
  }

  .page-template-template-agency .page-header>.row .col-md-12 {
    padding-top: 20px;
    padding-bottom: 270px;
    // background: url(../images/agency-banner-mobile.jpg) no-repeat center bottom;
    background-position: center bottom;
    background-size: 560px;
    // text-align: center;
    overflow: hidden;

    .btn {
      position: relative;
      z-index: 5;
    }

    .inner-container {
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 150%;
        position: absolute;
        top: 0;
        left: -25%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
      }

      &:after {
        content: "";
        display: block;
        width: 150%;
        position: absolute;
        top: 100%;
        left: -25%;
        height: 60px;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.8) 0%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.8) 0%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%,rgba(255,255,255,0) 100%);
      }
    }
  }

  .page-template-template-agency .main .services .service-list img {
    max-width: 130px;
  }

  .page-template-template-agency .main .value-prop .vp-list {
    z-index: 5;
    padding: 0 0 0 80px;
    background: url(https://pixelter.com/wp-content/uploads/2018/11/agency-usp.jpg) no-repeat -220px 80px;
    background-size: 500px;
  }

  .page-template-template-agency .main .services .service-list>.row {
    border: 1px solid #f7f4f4;
    box-shadow: 0 4px 42px rgba(0,0,0,.05);
    padding: 20px 6px 30px;
    margin-bottom: 15px;
  }

  .page-template-template-plan .main .main-content-wrap .pricing-title {
    margin-bottom: 15px;

    h2 {
      font-size: 32px;
    }
  }

  .hp-testimonials-wrap {
    display: block;

    &:not(.slick-initialized) .col-md-6:not(:first-child) {
      display: none;
    }
  }

  .hp-content .section-clients h3 {
    margin-bottom: 15px;
    font-size: 19px;
  }

  .hp-content .section-clients .col-md-6+.col-md-6 {
    margin-top: 0 !important;
  }

  .slick-dots {
    display: block;
    list-style: none;
    margin: 0;
    padding: 5px 0 0;
    text-align: center;
    background: #fff;

    li {
      display: inline-block;
      margin: 0 2px;

      &:only-child {
        display: none;
      }

      &.slick-active button {
        background: #FFC266;
      }

      button {
        width: .625rem;
        height: .625rem;
        border: none;
        display: block;
        text-indent: -9999px;
        border-radius: 100%;
        padding: 0;
        background: #eee;
      }
    }
  }

  .hp-content .case-study-section .case-study .image-link:before {
    z-index: 4;
  }
  .hp-content .case-study-section .case-study .image-link:after {
    z-index: 8;
  }

  .hp-content .case-study-section .case-study .cs-logo-wrap {
    margin-top: 5px;
  }

  .page-template-template-case-studies .case-studies .cs-detail .cs-text {
    padding-top: 0;
  }

  .hp-content .case-study-section .section-content .row {
    display: block;
  }

  .page-template-template-plan .main .main-content-wrap .pricing-package  {
    margin-bottom: 40px;

    &.recommended {
      margin-top: 20px;
    }

    .pricing-package-inner {
      padding-top: 20px;
    }

    h4 {
      font-size: 22px;
    }

    .summary {
      padding-bottom: 1rem;
    }

  }

  .hp-content .hp-plans .conversion-audit .plan-wrap,
  .hp-content .hp-plans .plan-wrap,
  .hp-content .hp-plans .shopify-development .plan-wrap {
    padding-top: 8px !important;
    padding-bottom: 35px !important;
  }

  .hp-content .hp-plans .conversion-audit-img,
  .hp-content .hp-plans .conversion-boost-img,
  .hp-content .hp-plans .shopify-development-img {
    float: left;

    img {
      width: 220px;
    }
  }

  .hp-content .hp-plans .line-divider {
    margin-bottom: 90px;
  }

  .hp-content .hp-plans .line-divider.line-divider--bottom {
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .hp-content .case-study-section .case-study .image-link:after {
    width: 100%;
    height: 35px;
    top: auto;
    bottom: 0;
    background: url(../images/mobile-banner-overlay.svg) no-repeat right bottom;
    background-size: cover;
  }

  .hp-content .hp-plans p a.btn-primary {
    width: auto;
    padding: .65rem 1.25rem;
  }

  .hp-content .hp-plans h3 {
    font-size: 24px;
  }

  .hp-content .hp-plans p {
    font-size: 15px;
  }

  .hp-content .top-section .section-intro h3 {
    font-size: 17px;
    padding: 6px 14px;
  }

  .page-template-template-agency .page-header>.row p {
    width: 100%;
    font-size: 16px !important;
    padding-top: 10px;
  }

  .page-template-template-agency .page-header>.row h1 {
    font-size: 30px;
    line-height: 34px;

    br {
      display: none;
    }
  }

  .page-template-template-agency .main .testimonials .testimonial-list>div {
    margin-bottom: 10px;
  }

  .page-template-template-agency .page-header>.row {
    background-position: top left;
    margin-bottom: 0;
    padding-top: 78px !important;
  }

  .page-template-template-agency .main .section-intro h2 {
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 300;

    strong {
      display: inline;
      text-transform: none;
    }
  }

  .page-template-template-agency .main .about-us h2 {
    padding: 12px 15px;
    font-size: 28px;
  }

  .page-template-template-agency .main .services .service-list .service-inner {
    padding: 15px 0 0 !important;
  }

  .page-template-template-agency .main .value-prop .vp-bg {
    display: none;
  }

  .page-template-template-agency .main .value-prop .vp-list .vp-box {
    background: rgba(255, 255, 255, 0.95);
  }

  .page-template-template-agency .main .value-prop .value-prop-content {
    max-width: 100%;
    padding-right: 2%;
  }

  .page-template-template-agency .main .about-us,
  .page-template-template-agency .main .cta-wrap,
  .page-template-template-agency .main .services,
  .page-template-template-agency .main .testimonials {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .page-template-template-agency .main .cta-wrap h2 {
    font-weight: bold;
  }

  .page-template-template-agency .page-header>.row .inner-container {
    // width: 94%;
  }

  .page-template-template-agency .main .about-us {
    padding-bottom: 20px;
  }

  .page-template-template-agency .main .value-prop {
    padding-bottom: 15px;
  }

  .page-template-template-agency .page-header>.row .partnership {
    padding: 8px 12px;
    font-size: 13px;
  }

  .page-template-template-agency .main .testimonials .col-lg-4 {
    margin-bottom: 30px;
  }

  .page-template-template-agency .main .value-prop .vp-list p {
    font-size: 14px;
  }

  .page-template-template-agency .main .value-prop .vp-list i {
    float: right;
    color: #969696;
    margin-top: 7px;
  }

  .page-template-template-agency .main .value-prop .vp-list img {
    width: 30px;
    float: left;
    margin-bottom: 0;
  }

  .page-template-template-agency .main .value-prop .vp-list h3 {
    font-size: 17px;
  }

  .page-template-template-agency .main .section-intro p,
  .page-template-template-agency .main .value-prop .section-intro p {
    font-size: 17px;
    line-height: 1.4em;
  }

  .page-template-template-agency .main .value-prop .vp-list .col-lg-6 {
    margin-bottom: 0;
    border: 1px solid #eee;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid #eee;
      border-radius: 0 0 5px 5px;
    }

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &.opened {
      p {
        display: block;
      }

      i {
        display: none;
      }
    }
  }

  .new-case-study-layout.single-case-study .page-header>.row {
    padding-bottom: 30px !important;
    margin-bottom: 35px !important;
  }

  .new-case-study-layout .page-header p {
    // padding: 10px 15px;
    display: block;
  }

  .new-case-study-layout .page-header h1 {
    font-size: 31px !important;
    // padding: 10px 15px;
    display: block;
  }

  .new-case-study-layout .case-study-content .content-section .solution-details h3 {
    font-size: 23px!important;
  }

  .new-case-study-layout .case-study-content .content-section .solution-details h4.small-headline {
    font-size: 16px !important;
  }

  .new-case-study-layout .content-section h5 {
    font-size: 20px;
  }

  .new-case-study-layout .content-section .lg-testimonial-text {
    margin-bottom: 20px;
  }

  .new-case-study-layout .case-study-content .content-section .services-list-wrap ul li {
    float: none;
    width: 100%;
    border-right: none;
    padding: 14px 15px;
    margin: 0;
    font-size: 15px;
  }

  .new-case-study-layout .results-wrap-inner .data-point {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .new-case-study-layout .results-wrap-inner .data-point strong {
    font-size: 27px;
  }

  .pricing-page.page-template-template-pricing-v2 .main .page-header p {
    font-weight: normal;
  }

  .pricing-page.page-template-template-pricing-v2 .main .main-content-wrap {
    padding-top: 0;
  }

  .pricing-page.page-template-template-pricing-v2 .services-wrap .row+.row {

  }

  .pricing-page.page-template-template-pricing-v2 .main .main-content-wrap {

    .services-wrap {
      .service-image {
        img {
          width: 230px;
          margin-bottom: 12px;
        }
      }

      .service-content {
        max-width: 100%;
        padding: 5px 15px 0;
        margin: 0;
        background: #fff;

         // .service-content-inner {
         //   max-width: 100%;
         // }


        h2 {
          font-size: 23px;
          font-weight: bold;
        }
      }
    }
    .audit-package,
    .cro-package {
      padding-top: 0;

      // .package-title-wrap {
      //   margin: 0 -25px 15px;
      //   background: red;
      //   padding: 25px 25px;
      //
      //   h2 {
      //     margin: 0;
      //     background: #79923b;
      //     padding: 8px 10px;
      //
      //     a, i {
      //       color: #fff;
      //     }
      //   }
      //
      //   p.package-tip {
      //     margin: 0;
      //     // background: #8bab3e;
      //     // color: #fff;
      //     background: #fff;
      //     padding: 8px 10px;
      //     line-height: 1.2em;
      //   }
      // }
    }
    // .audit-package .package-title-wrap {
    //   background: url(../images/conversion-audit.png) no-repeat;
    //   background-size: cover;
    // }
    //
    // .cro-package .package-title-wrap {
    //   background: url(../images/conversion-boost.png) no-repeat;
    //   background-size: cover;
    // }
  }

  .mobile-onpage-nav {
    margin: 20px 0;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      border: 1px solid #e8e8e8;
      border-radius: 5px;

      li {
        margin: 0 !important;
        border-bottom: 1px solid #e8e8e8;

        &:last-child {
          border-bottom: none;
        }

        a {
          display: block;
          padding: 13px 20px;
          font-size: 16px;
          color: #373a3c;
          font-weight: bold;
          text-decoration: none !important;

          i {
            float: right;
            line-height: 23px;
            color: #79923b;
            font-size: 24px;
          }
        }
      }
    }
  }

  body.tools .online-tool-banner {
    height: auto;
  }

  body.tools .online-tool-banner p {
    margin-bottom: 20px;
  }

  body.tools .online-tool-banner .banner-content-wrap {
    padding: 42px 30px;
    width: 100%;
    background: rgba(46,48,61,.85);
  }

  .hp-content .top-banner>.row:after {
    height: 140px;
  }

  .hp-content .clients-section {
    padding-top: 25px;
    padding-bottom: 55px;
  }

  .hp-content .section-clients .client-logos {
    padding-top: 35px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .intro-plan>p,
  .page-template-template-plan .main .main-content-wrap .intro-plan>p {
    border-left: 2px solid #ffc266;
    font-size: 17px;
    padding-left: 15px;
    margin-bottom: 20px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .lg-testimonial-text,
  .page-template-template-plan .main .main-content-wrap .lg-testimonial-text {
    margin-bottom: 20px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .lg-testimonial-text p,
  .page-template-template-plan .main .main-content-wrap .lg-testimonial-text p {
    font-size: 15px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .process-header h3,
  .page-template-template-plan .main .main-content-wrap .process-header h3 {
    display: block;
    font-size: 30px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .audit-content-list li span,
  .page-template-template-plan .main .main-content-wrap .audit-content-list li span {
    display: none;
  }

  .page-template-template-plan-wide .main .main-content-wrap .audit-content-list li span,
  .page-template-template-plan .main .main-content-wrap .col-md-6 + .col-md-6 {
    margin-top: 20px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .divider-wrap,
  .page-template-template-plan .main .main-content-wrap .divider-wrap {
    margin-top: 25px;
    margin-bottom: 40px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .process-header h4,
  .page-template-template-plan .main .main-content-wrap .process-header h4 {
    display: block;
    background: #F08260;
  }

  .hp-content .case-study-section .bottom-stats .col-md-4 {
    border-left: 1px solid #eee;
    border-bottom: none;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .hp-content .case-study-section .bottom-stats .col-md-4.third {
    border-bottom: 1px solid #eee;
  }

  .page-template-template-services-v2 .section-plans .plan-wrap,
  .hp-content .hp-plans .plan-wrap {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .page-template-template-services-v2 .section-plans .plan-wrap p,
  .hp-content .hp-plans .plan-wrap p {
    margin-bottom: 0;
  }

  .page-template-template-plan-wide .page-header h1,
  .page-template-template-plan .page-header h1 {
    font-size: 31px !important;
    padding: 0 15px;
    display: block;
  }

  .page-template-template-plan-wide.conversion-boost .main .page-header>.row,
  .page-template-template-plan-wide.conversion-audit .main .page-header>.row,
  .page-template-template-plan.conversion-boost .main .page-header>.row,
  .page-template-template-plan.conversion-audit .main .page-header>.row {
    margin-bottom: 40px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .process-header,
  .page-template-template-plan .main .main-content-wrap .process-header {
    text-align: center;

    br {
      display: none;
    }
  }

  .page-template-template-plan-wide .page-header p,
  .page-template-template-plan .page-header p {
    padding: 5px 15px;
    display: block;
  }

  .pricing-page .pricing-wrap>.row {
    background: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .page-template-template-services-v2 .page-header, .page-template-template-services .page-header {
    margin-bottom: 25px;
  }
  .page-template-template-services-v2 .page-header p, .page-template-template-services .page-header p,
  .page-template-template-services-v2 .page-header h1, .page-template-template-services .page-header h1 {
    padding: 15px 15px;
  }
  .page-template-template-landing-page.page-id-1691 .lp-offer-banner {
    font-size: 14px;
  }

  .hp-content .case-study-section .case-study .image-link {
    float: none;
    margin: 0 -20px;
    height: 130px;
    overflow: hidden;
  }

  .hp-content .case-study-section .section-intro h2 {
    margin-bottom: 5px;
  }

  .hp-content .case-study-section .section-content .row {
    &:not(.slick-initialized) .case-study:not(:first-child) {
      display: none;
    }
  }

  .hp-content .case-study-section .case-study .image-link img {
    position: relative;
    top: -70px;
  }

  .hp-content .case-study-section .case-study {
    padding: 0 20px 20px;
  }

  .hp-content .lead-magnet .content-wrap p {
    text-align: left;
  }

  .page-template-template-landing-page.page-id-1691 .landing-page-title h1 {
    font-size: 29px;
  }

  // .hp-content .top-banner h2,
  // .hp-content .top-banner p {
  //   padding: 20px 25px;
  // }

  .hp-content .top-banner p {
    width: 100%;
  }

  .hp-content .section-clients .col-md-6 + .col-md-6 {
    margin-top: 25px;
  }

  .page-template-template-thank-you .thank-you-content,
  .error404 .error-content,
  .page-template-template-landing-page .contact-content .inner-container,
  .page-template-template-contact .contact-content .inner-container,
  .page-template-template-about .about-content .content-section,
  .single-case-study .case-study-content .content-section,
  .page-template-template-case-studies .case-studies .inner-container,
  .page-template-template-services .service-content .content-section,
  .hp-content .bottom-section > .row,
  .hp-content .top-section,
  .main .main-content-wrap {
      padding-top: 60px;
      padding-bottom: 55px;
  }

  .hp-content .top-banner .banner-content {
    padding-top: 100px;
    height: auto;
    padding-bottom: 160px;

    & > .row {
      height: auto;
    }
  }

  .hp-content .top-banner h2 {
    font-size: 36px;
    line-height: 37px;
  }

  .hp-content .section-clients blockquote+p img {
    width: 45px;
    float: left;
  }

  .hp-content .section-clients blockquote+p {
    font-size: 14px;
    line-height: 1.25em
  }

  .hp-content .section-clients blockquote+p span {
    display: block;
    padding-top: 7px;
  }

  .hp-content .top-section .section-intro h2 {
    font-size: 24px;
    margin-bottom: 25px;
    font-weight: 300;

    br {
      display: none;
    }
  }

  .hp-content .top-section .section-intro p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .hp-content .top-section .section-content p {
    font-size: 15px;
    margin: 0;
  }

  .hp-content .top-section .section-intro {
    margin-bottom: 10px;
  }

  .hp-content .top-section .section-content .image-wrap {
    margin-bottom: 20px;
  }

  .hp-content .top-section {
    padding-bottom: 55px;
  }

  .hp-content .case-study-section {
    padding-top: 40px;
    padding-bottom: 55px;
  }

  .hp-content .case-study-section .case-study {
    margin-bottom: 10px;
  }

  .hp-content .case-study-section .case-study h5 a {
    text-decoration: none;
    font-size: 21px;
  }

  .main .page-header p,
  .page-template-template-services-v2 .main .page-header p {
    font-size: 16px !important;
    line-height: 1.35em !important;
    font-weight: normal;
  }

  .page-template-template-services-v2 .service-content .content-section.section-1 .section-item h3 {
    margin-bottom: 3px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .plan-tabs .plan-tab .tab-content p,
  .page-template-template-plan-wide .main .main-content-wrap .plan-tabs .plan-tab .tab-content li,
  .page-template-template-plan-wide .main .main-content-wrap li, .page-template-template-plan .main .main-content-wrap p,
  .page-template-template-plan .main .main-content-wrap .plan-tabs .plan-tab .tab-content p,
  .page-template-template-plan .main .main-content-wrap .plan-tabs .plan-tab .tab-content li,
  .page-template-template-plan .main .main-content-wrap li, .page-template-template-plan .main .main-content-wrap p {
    font-size: 16px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .plan-tabs .plan-tab h3,
  .page-template-template-plan .main .main-content-wrap .plan-tabs .plan-tab h3 {
    font-size: 17px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .plan-tabs .plan-tab .tab-content .tab-content-inner,
  .page-template-template-plan .main .main-content-wrap .plan-tabs .plan-tab .tab-content .tab-content-inner {
    padding: 18px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .contact-form form,
  .page-template-template-plan .main .main-content-wrap .contact-form form {
    padding: 20px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .contact-form,
  .page-template-template-plan .main .main-content-wrap .contact-form {
    margin-bottom: 35px;
  }
  // .error404 .error-content, .hp-content .bottom-section>.row, .hp-content .top-section, .main .main-content-wrap, .page-template-template-about .about-content .content-section, .page-template-template-case-studies .case-studies .inner-container, .page-template-template-contact .contact-content .inner-container, .page-template-template-landing-page .contact-content .inner-container, .page-template-template-services .service-content .content-section, .page-template-template-thank-you .thank-you-content, .single-case-study .case-study-content .content-section,
  // .page-template-template-case-studies .case-studies .inner-container {
  //   padding-top: 20px;
  // }

  .single-case-study .page-header>.row {
    padding-bottom: 0 !important;
  }

  .page-template-template-plan-wide .main .main-content-wrap .plan-testimonial blockquote+p,
  .page-template-template-plan .main .main-content-wrap .plan-testimonial blockquote+p {
    font-size: 14px;
    line-height: 1.25em;
  }


  .page-template-template-services-v2 .service-content .content-section.section-1 .section-item > div {
    margin-bottom: 12px;
  }


  .page-template-template-case-studies .case-studies .inner-container {
    padding-top: 0;
  }


  .page-template-template-plan-wide .main .main-content-wrap .plan-testimonial blockquote+p img,
  .page-template-template-plan .main .main-content-wrap .plan-testimonial blockquote+p img {
    width: 45px;
    float: left;
  }

  .page-template-template-plan-wide .main .main-content-wrap .plan-testimonial blockquote+p span,
  .page-template-template-plan .main .main-content-wrap .plan-testimonial blockquote+p span {
    display: block;
    padding-top: 7px;
  }

  .page-template-template-services-v2 .service-content .content-section.section-1 .section-item img {
    margin-bottom: 6px;
  }

  .main .page-header .inner-container > div {
    padding-left: 0;
    padding-right: 0;
  }

  .hp-content .top-banner p {
    font-size: 17px;
    margin-bottom: 20px;
    // font-weight: 300;

    br {
      display: none;
    }
  }

  .hp-content .top-banner .trust-logos {
    padding-bottom: 12px;
  }

  .hp-content .top-banner .trust-logos p {
    line-height: 50px;
    margin: 0;
  }
  .content-section .section-intro h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .content-section .section-intro p {
    font-size: 18px;
    font-weight: normal;
  }

  .page-template-template-services-v2 .section-2 img {
    margin-top: 10px !important;
  }

  .page-template-template-services-v2 .section-3 .delivery, .page-template-template-services .section-3 .delivery {
    margin-bottom: 10px;
  }

  .page-template-template-services-v2 .cta-banner {
    margin-top: 15px;
  }

  .content-section .section-intro {
    margin-bottom: 20px;
  }

  .hp-content .top-section .section-content .content-wrap {
    padding-top: 0px;
  }

  .hp-content .top-section .section-content i {
    top: 0;
  }
  .hp-content .top-section .section-content li {
    padding-bottom: 15px;
    padding-top: 15px;
    border-left: 6px solid #FFC266;
    padding-left: 15px;
    margin: 0;

    &:nth-child(2) {
      border-left-color: #f5bd66;
    }

    &:nth-child(3) {
      border-left-color: #ecb35c;
    }
  }
  .hp-content .top-section .section-content .image-wrap img {
    margin-top: 10px;
  }

  .hp-content .middle-section .stages h3 span {
    margin-bottom: 12px;
  }

  .hp-content .middle-section .stages {
    margin-bottom: 0;
  }

  .hp-content .middle-section .section-graph {
    margin-top: 10px;
  }

  .hp-content .middle-section .stages .stage {
    margin-bottom: 15px;
  }

  .hp-content .bottom-section .single-testimonial .testimonial-text {
    padding-bottom: 20px;
  }

  .hp-content .lead-magnet > .row {
    padding: 60px 0 55px;
  }

  .hp-content .lead-magnet h2 {
    font-size: 28px;
    margin-bottom: 15px !important;
    font-weight: 300;
  }

  .hp-content .lead-magnet.content-section .section-intro {
    margin-bottom: 10px;
  }

  .hp-content .lead-magnet .content-wrap form input[type=text], .hp-content .lead-magnet .content-wrap form input[type=email] {
    width: 100%;
  }

  .hp-content .lead-magnet .image-wrap {
    margin: 10px 0 20px;
    img {
      max-width: 140px;
    }
  }

  .hp-content .lead-magnet.content-section  {
    text-align: center;
  }

  .hp-content .lead-magnet .content-wrap p {
    font-size: 14px;
  }

  .hp-content .lead-magnet > .row {
    background-size: cover;
  }
  footer.main-footer p, footer.main-footer ul {
    float: none;
    margin: 0 0 5px;
    text-align: center;
  }

  footer.main-footer .copy p {
    margin: 0;
    line-height: 1.1em;
  }

  footer.main-footer nav li:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  footer.main-footer nav,
  footer.main-footer .social .widget {
    float: none;
  }
  footer.main-footer .social p {
    display: none;
  }

  footer.main-footer .inner-container .row {
    width: auto;
  }

  .main .page-header > .row {
    padding-top: 105px;
    padding-bottom: 30px;
  }

  .main .page-header .inner-container {
    max-width: 100%;
  }

  .main .page-header h1 {
    font-size: 40px;
    line-height: 1.1em;
    white-space: normal;
  }

  .page-template-template-plan-wide .main .main-content-wrap .cro-stage h3,
  .page-template-template-plan .main .main-content-wrap .cro-stage h3 {
    background: none;
  }

  .page-template-template-plan-wide .main .main-content-wrap .cro-stage h3 .stage,
  .page-template-template-plan .main .main-content-wrap .cro-stage h3 .stage {
    background: #ffc266;
    float: none;
  }

  .page-template-template-plan-wide .main .main-content-wrap .cro-stage h3 .step-name,
  .page-template-template-plan .main .main-content-wrap .cro-stage h3 .step-name {
      clear: both;
  }

  .page-template-template-plan-wide .main .main-content-wrap .cro-stage h3 .stage,
  .page-template-template-plan-wide .main .main-content-wrap .cro-stage h3 .step-name,
  .page-template-template-plan .main .main-content-wrap .cro-stage h3 .stage,
  .page-template-template-plan .main .main-content-wrap .cro-stage h3 .step-name {
    padding: 10px 15px;
  }

  .page-template-template-plan-wide .main .main-content-wrap .audit-content-list.audit-content-list-flat li,
  .page-template-template-plan .main .main-content-wrap .audit-content-list.audit-content-list-flat li {
    width: auto;
    float: none;
  }

  .main .page-header p {
    font-size: 15px;
    font-weight: normal;
  }

  .page-template-template-case-studies .case-studies .cs-detail {
    margin-bottom: 30px;

    .cs-text {
      padding: 0 20px 30px !important
    }

    h2 {
      a {
        font-size: 19px;
      }
    }
    .cs-image,
    .cs-image.cs-image-right {
      padding: 0 !important;
      margin-bottom: 5px;

      a {
        height: 150px;
        overflow: hidden;

        img {
          position: relative;
          top: -80px;
        }

        &:before {
          z-index: 4;
        }

        &:after {
          width: 100%;
          height: 35px;
          top: auto;
          bottom: -1px;
          background: url(../images/mobile-banner-overlay.svg) no-repeat right bottom;
          background-size: cover;
          z-index: 6;
        }
      }
    }
  }

  .main .page-header > .row {
    background-size: cover;
  }

  .page-template-template-services .page-header .our-results li {
    width: 75px;
    height: 75px;
    padding-top: 22px;
    margin: 0 4px;
  }

  .btn-primary {
    font-weight: 300;
    width: 100%;
  }

  header.banner .brand {
    margin: 0;
  }

  header.banner .brand a {
    position: relative;
    z-index: 10;
  }

  .page-template-template-services .page-header .our-results li span {
    font-size: 17px;
  }
  .page-template-template-services .page-header .our-results li {
    font-size: 12px;
  }

  .page-template-template-services .section-2 .image-wrap {
    padding-top: 0;
    margin-bottom: 20px;
  }

  .page-template-template-services .section-2 .section-intro {
    margin-bottom: 10px;
  }

  .page-template-template-services .section-3 .section-intro {
    margin-bottom: 25px;
  }

  .page-template-template-services .case-study-section .case-study:not(:last-child) {
    border-right-color: #e8e8e8;
  }

  .page-template-template-services .case-study-section .case-study {
    margin-bottom: 10px;
  }

  .page-template-template-about .about-content .bottom-cta .btn,
  .pricing-page .bottom-cta .btn,
  .page-template-template-services .bottom-cta .btn {
    display: block;
    margin: 0 auto 20px;
    max-width: 220px;
  }

  .page-template-template-services .service-content .top-section .content-info img {
    width: 400px;
    display: block;
    margin-bottom: 20px;
  }

  .page-template-template-services .service-content .top-section .content-info .step-1 {
    margin-bottom: 30px;
  }

  .page-template-template-services .service-content .top-section .content-info .step-1,
  .page-template-template-services .service-content .top-section .content-info .step-2 {
    border-top-width: 3px;
  }

  .page-template-template-services .service-content .top-section .content-info .step-1, .page-template-template-services .service-content .top-section .content-info .step-2 {
    padding-top: 16px;
  }

  .pricing-page .pricing-wrap .row > div {
    padding: 25px 15px;
    margin-bottom: 15px;
    border: 1px solid #e8e8e8 !important;
  }

  .pricing-page .pricing-wrap h2 br {
    display: none;
  }

  .pricing-page .pricing-wrap h2 {
    margin-bottom: 15px;
  }

  .pricing-page .pricing-wrap .row .top-plan-wrap {
    box-shadow: none;
    padding-top: 50px;
  }

  .pricing-page .pricing-wrap .top-plan {
    box-shadow: none;
    top: 0;
    left: 0;
    width: 100%;
  }

  .pricing-page .pricing-wrap .row {
    border: none;
    margin-bottom: 25px;
  }

  .main .cta-banner .row {
    text-align: center;
  }

  .main .cta-banner .row > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .main .cta-banner .btn {
    float: none;
  }

  .main .cta-banner h3 {
    font-size: 20px;
    float: none;
  }

  .main .cta-banner p {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
    float: none;
  }

  .pricing-page .faq {
    padding-top: 80px;
    // margin-top: 50px;
  }
  .pricing-page .faq .faq-title {
    margin-bottom: 20px;
  }

  .pricing-page .faq .faq-questions {
    margin-bottom: 0;
  }

  .pricing-page .faq .faq-questions .row > div {
    margin-bottom: 25px;
  }
  .page-template-template-case-studies .case-studies .cs-image {
    margin-bottom: 25px;
  }

  // .page-template-template-case-studies .case-studies .cs-detail:last-child {
  //   margin-bottom: 0;
  // }

  .single-case-study .case-study-content .section-content .content-wrap {
    margin-bottom: 20px;
  }

  .single-case-study .case-study-content .bottom-section .content-wrap {
    margin-bottom: 50px;
  }

  .single-case-study .bottom-cta {
    padding-top: 0;
    padding-bottom: 70px;
  }

  .page-template-template-about .about-content .top-section .section-intro {
    margin-bottom: 20px;
  }

  .page-template-template-about .about-content .team-section .section-intro {
    margin-bottom: 20px;
  }

  .page-template-template-about .about-content .team-section .small-main-img {
    @include border-radius(100%);
    float: left;
    margin-right: 12px;
    margin-bottom: 20px;
  }

  .page-template-template-about .about-content .team-section h2 {
    margin-bottom: 8px;
  }

  .page-template-template-about .about-content .team-section .section-content h3 {
    font-size: 22px;
    margin-bottom: 2px;
    margin-top: 8px;
  }

  .page-template-template-about .about-content .team-section .section-content h4 {
    font-size: 15px;
  }

  .page-template-template-about .about-content .team-section .section-content p {
    clear: both;
  }

  .page-template-template-about .about-content .team-section .section-content .btn {
    padding: 0.65rem;
  }

  .page-template-template-about .about-content .team-section .section-content .contact-links li {
    margin-right: 2px;
  }

  .page-template-template-about .about-content .team-section .section-content .contact-links {
    margin-right: 5px;
    margin-bottom: 8px;
  }

  // .inner-container {
  //   max-width: 100%;
  // }

  .page-template-template-about .about-content .team-section .section-content {
    margin-bottom: 50px;
  }

  .page-template-template-about .about-content .team-section .section-members .team-member img {
    width: 60px;
  }

  .page-template-template-about .about-content .team-section .section-members .team-member h5 {
    font-size: 16px;
    margin-top: 13px;
  }

  .page-template-template-about .about-content .team-section .section-members .team-member p {
    font-size: 13px;
  }

  .page-template-template-about .about-content .team-section .section-members .team-member {
    margin-bottom: 18px;
  }

  .page-template-template-about .about-content .team-section .section-certificates {
    padding-top: 30px;
  }

  .page-template-template-about .about-content .team-section .section-content .contact-links li a {
    width: 35px;
    line-height: 35px;
    height: 35px;
  }

  .page-template-template-about .about-content .bottom-section .case-study {
    margin-bottom: 15px;
  }

  .page-template-template-about .about-content .bottom-section .case-study:not(:last-child) {
    border-right-color: #e8e8e8;
  }

  .page-template-template-about .content-section .section-intro p {
    font-weight: normal;
  }

  .page-template-template-landing-page .contact-form-wrap form, .page-template-template-contact .contact-form-wrap form {
    padding: 30px 20px;
  }

  .page-template-template-contact .contact-content {
    margin-bottom: 40px;
  }

  .page-template-template-contact .main .page-header h1 {
    font-size: 29px;
    margin-bottom: 0;
  }

  .error404 .error-content .case-study:not(:last-child) {
    border-right-color: #e8e8e8;
  }

  .error404 .error-content .case-study {
    margin-bottom: 15px;
  }

  .page-template-template-landing-page header.banner .main-nav-wrap {
    display: none;
  }

  .page-template-template-landing-page header.banner .container {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .page-template-template-landing-page .landing-page-title {
    padding-top: 30px;
  }

  .page-template-template-landing-page .landing-page-title h1 {
    font-size: 28px;
  }

  .page-template-template-landing-page .contact-content .inner-container {
    padding-top: 20px;
  }

  .page-template-template-landing-page .contact-content {
    p, ul li {
      font-size: 15px;
    }
  }

  .page-template-template-landing-page .contact-content {
    margin-bottom: 40px;
  }

  .page-template-template-landing-page .contact-content .inner-container {
    padding-bottom: 0;
  }

  .page-template-template-landing-page .contact-form-wrap {
    margin-bottom: 30px;
  }

  .page-template-template-landing-page .contact-content .ebook {
    max-width: 120px;
    margin-bottom: 20px;
  }

  .page-template-template-landing-page .contact-content .signature img {
    width: 50px;
    margin-right: 10px;
  }

  .page-template-template-landing-page .contact-content .signature span.name {
    font-size: 16px;
    padding-top: 7px;
  }

  .page-template-template-landing-page .contact-content .signature span.job {
    font-size: 14px;
  }

  .page-template-template-thank-you .thank-you-content .blog-post-list .blog-post:not(:last-child) {
    border-right-color: #e8e8e8;
  }

  .page-template-template-thank-you .thank-you-content .blog-post-list .blog-post {
    margin-bottom: 15px;
  }

  header.banner .nav-primary li a {
    font-size: 17px;
    line-height: 21px;
    padding: 20px 0;
  }

  .hp-content .top-banner .secondary-cta {
    font-weight: 300;
  }

  .hp-content .middle-section {
    margin-top: -160px;
  }

  .page-template-template-services .quiz-section .content-wrap .quiz-results {
    padding-left: 30px;
    padding-right: 30px;
  }
  iframe {
    max-width: 100%;
  }

  .page-template-template-services .case-study-section .case-study .btn {
    display: block;
    clear: both;
  }
  .single-case-study .case-study-content .content-section h3 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  .page-template-template-contact .bottom-content h2, .page-template-template-landing-page .bottom-content h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }

  .page-template-template-contact .bottom-content .contact-info, .page-template-template-landing-page .bottom-content .contact-info {
    margin-bottom: 30px;
  }

  .page-template-template-about .about-content .top-section .client-logos > .row {
    margin: 0;
  }

  .page-template-template-about .about-content .top-section .client-logos .col {
    padding: 0 20px;
    line-height: 95px;
    width: 33%;

    &:last-child {
      display: none;
    }
  }

  .page-template-template-about .about-content .top-section .image-wrap {
    display: none;
  }

  .page-template-template-about .about-content .top-section .image-wrap {
    margin-top: 30px;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap .row>div {
    padding: 25px;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap h2  {
    font-size: 23px;
    font-weight: bold;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap p {
    font-size: 14px;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap .row>div.top-plan-wrap {
    padding-top: 52px;
  }

  .pricing-page.page-template-template-pricing-v2 .pricing-wrap>.row>.col-md-12 {
    border: none !important;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .inner-container .row {
    margin-top: 40px;
    padding-top: 60px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details h2 {
    font-size: 30px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .plan-title-wrap i {
    position: static;
    margin-bottom: 3px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .plan-title-wrap {
    padding-left: 0;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details li,
  .pricing-page.page-template-template-pricing-v2 .plan-details p {
    font-size: 14px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .icon-list i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .icon-list li {
    padding-left: 50px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details ul {
    padding-left: 25px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details h4 {
    font-size: 18px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .plan-pricing-wrap .left {
    float: none;
    width: 100%;
    margin-bottom: 13px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .plan-pricing-wrap .right {
    float: none;
    width: 100%;

    .btn {
      padding: .6rem 1rem;
      font-size: 14px;
      float: none;
    }
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .plan-pricing-wrap {
    padding: 25px;
    text-align: center;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .step-list .step {
    width: 50%;
    padding-left: 15px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .step-list .step h4 {
    font-size: 15px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .step-list .step h4 span {
    font-size: 12px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .step-content {
    padding: 25px 15px;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .step-list .step.step-2 {
    border-right: 1px solid #e2ebcd;
  }

  .pricing-page.page-template-template-pricing-v2 .plan-details .step-list .step.step-3 {
    border-left: 1px solid #c5d69b;
  }

  .main .page-header>.row {
    padding-top: 100px;
  }

  .single-case-study .case-study-content .content-section .testimonial-box h5 img {
    width: 45px;
    float: left;
    position: relative;
    top: -10px;
  }

  // .error404 .error-content, .hp-content .bottom-section>.row, .hp-content .top-section, .main .main-content-wrap, .page-template-template-about .about-content .content-section, .page-template-template-case-studies .case-studies .inner-container, .page-template-template-contact .contact-content .inner-container, .page-template-template-landing-page .contact-content .inner-container, .page-template-template-services .service-content .content-section, .page-template-template-thank-you .thank-you-content, .single-case-study .case-study-content .content-section {
  //   padding-bottom: 15px;
  // }

  .page-template-template-services-v2 .service-content .content-section.section-1 {
    padding-bottom: 20px;
  }

  .page-template-template-about-v2 .main .main-content-wrap p {
    font-size: 17px;
    margin-bottom: 1rem;
  }

  .page-template-template-about-v2 .main .main-content-wrap p.intro {
    font-size: 21px;
  }

  .single-case-study .page-header>.row h1 {
    font-size: 30px;
  }

  .page-template-template-services-v2 .main .page-header p {
    font-size: 18px !important;
  }

  .single-case-study .case-study-content .content-section {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .page-template-template-about-v2 .main .main-content-wrap h3 {
    font-size: 25px;
    margin-top: 45px;
  }

  .page-template-template-about-v2 .main .main-content-wrap h5 {
    margin-top: 45px;
    font-size: 18px;
  }

  .single-case-study .case-study-content .content-section .testimonial-box h5 span {
    display: block;
  }
  .page-template-template-about-v2 .main .main-content-wrap .testimonials-wrap > div {
    margin-bottom: 20px
  }

  .page-template-template-about-v2 .main .main-content-wrap .mobile-founder {
    line-height: 1.25em;
    font-size: 14px;
    margin-bottom: 32px;

    img {
      float: left;
      border-radius: 100%;
      margin-right: 10px
    }

    span {
      display: block;
      font-weight: bold;
      padding-top: 6px;
    }
  }

  .page-template-template-contact .contact-content .inner-container {
    padding-top: 0;

    .contact-form-wrap {
      margin-bottom: 40px;
    }
  }

  .page-template-template-contact .contact-content .contact-testimonial blockquote+p span {
    display: block;
    padding-top: 7px;
  }

  .page-template-template-contact .contact-content .contact-testimonial blockquote+p img {
      width: 45px;
      float: left;
  }

  .page-template-template-contact .contact-content .contact-testimonial blockquote+p {
    font-size: 14px;
    line-height: 1.25em;
  }
  .page-template-template-contact .contact-content {
    margin-bottom: 0;
  }

  .page-template-template-contact .contact-content .inner-container {
    padding-bottom: 40px;
  }

  .page-template-template-contact .contact-content .bottom-content .inner-container, .page-template-template-landing-page .contact-content .bottom-content .inner-container {
    padding-top: 50px;
  }

  .page-template-template-contact .bottom-content h2, .page-template-template-landing-page .bottom-content h2 {
    font-size: 30px;
  }

  .page-template-template-contact .contact-content .office-location, .page-template-template-landing-page .contact-content .office-location {
    margin-bottom: 40px;
  }

  .hp-content .top-banner>.row {
    background-size: cover;
  }

  body header.banner .nav-primary {
    border: 1px solid #e8e8e8;
  }

  .page-template-checkout-page .col2-set .col-2 {
    clear: both;
    padding-top: 25px;
  }

  .page-template-template-about-v2 .main .page-header h1 {
    margin-bottom: 0;
  }

  .page-template-template-plan-wide .main .main-content-wrap p {
    font-size: 15px;
  }

  .page-template-template-plan-wide.shopify-development .vp-list h4 {
    font-size: 17px;
  }

  .page-template-template-plan-wide.shopify-development .vp-list .col-lg-6 {
    margin-bottom: 10px;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-intro-wrap {
    padding-top: 45px;
    margin-top: 50px;
  }

  .page-template-template-plan-wide.shopify-development .section-intro h2 {
    font-size: 30px;
  }

  .page-template-template-plan-wide.shopify-development .section-intro h2 .shopify-logo {
    width: 130px;
    top: -3px;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap {
    background-size: 430px;
    background-position: center bottom !important;
  }

  .page-template-template-plan-wide.shopify-development .portfolio-wrap .portfolio-content,
  .page-template-template-plan-wide.shopify-development .portfolio-wrap:not(.potfolio-wrap-dark) .portfolio-content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 0;
    min-height: auto;
  }

  .page-template-template-plan-wide.shopify-development .main .page-header>.row {
    margin-bottom: 35px;
  }

  .page-template-template-plan-wide.shopify-development .main .main-content-wrap .top-into-image {
      margin-top: 20px;
      margin-bottom: 20px;
  }

  .page-template-template-plan-wide.shopify-development .main .main-content-wrap .portfolio-wrap,
  .page-template-template-plan-wide.shopify-development .main .main-content-wrap .portfolio-intro-wrap {
    overflow: hidden;
  }

  .page-template-template-pricing-v2 .service-content-inner .btn-primary {
    width: auto;
    font-size: 14px;
  }

}


@media (max-width: 600px) {
  .hp-content .top-banner .btn-primary {
    width: 100%;
    font-size: 17px;
    padding: 12px 10px;
  }

  .hp-content .top-banner>.row {
    background-position: top right -230px;
  }

  .page-template-template-landing-page-full .inner-container .special-offer p.top {
    font-size: 15px;
  }
  .page-template-template-landing-page-full .inner-container .special-offer p {
    font-size: 13px;
  }

  .page-template-template-landing-page-full .landing-page-title h1, .page-template-template-landing-page .landing-page-title h1 {
    font-size: 28px;
  }

  .page-template-template-landing-page-full .landing-page-title, .page-template-template-landing-page .landing-page-title {
    padding-top: 30px;
  }

  .hp-content .top-banner>.row>.col-md-12 {
    // background: linear-gradient(90deg,rgba(55,58,75,.89) 30%,rgba(121,146,59,.89));
  }

  .hp-content .top-banner h2 {
    font-size: 29px;
    line-height: 32px;
  }

  .hp-content .top-banner h2 br {
    display: none;
  }

  .hp-content .top-banner p {
    font-size: 16px;
    margin-bottom: 0;
    padding: 12px 0;
    line-height: 1.2em;
  }

  .hp-content .section-clients {
    padding-top: 0;
  }

  .hp-content .top-banner .banner-usp-list {
    margin-bottom: 25px;
  }

  .hp-content .top-banner .banner-usp-list li {
    float: none;
    width: auto;
    margin-bottom: 5px;
  }

  .hp-content .top-banner .btn-primary {
    font-weight: normal;
  }
}

@media (max-width: 450px) {
  .woocommerce-checkout .checkout-progress .progress-step .text-wrap {
    font-size: 12px;
    display: block;
  }

  .woocommerce-checkout .checkout-progress .progress-step .circle-wrap {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
  .woocommerce-checkout  {
    h3 {
      font-size: 20px;
    }

    form label {
      font-size: 14px;
    }
  }

  .woocommerce table.shop_table {
    font-size: 14px;
  }

  .woocommerce-checkout #payment ul.payment_methods li img {
    display: block;
    margin: 0;
  }

  .woocommerce-checkout #payment .payment_method_paypal .about_paypal {
    display: none;
  }
}

@media (max-width: 360px) {
  .page-template-template-case-studies .case-studies .cs-detail .improvement {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 12px;
  }
  .page-template-template-case-studies .case-studies .cs-detail h2 {
    margin-bottom: 4px;
  }
  .page-template-template-landing-page-full .inner-container .cta-wrap {
    padding: 15px;
  }

  .page-template-template-landing-page-full .inner-container .cta-wrap h4 {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .page-template-template-landing-page-full .inner-container .cta-wrap p {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .page-template-template-landing-page-full .inner-container .cta-wrap .price {
    margin-top: 10px;
  }

}
