body {
  font-family: "proxima-nova",sans-serif;
  // background: #ebeff0;
}

h1, h2, h3, h4, h5 {
  font-family: "proxima-nova",sans-serif;
}

.container {
  background: #fff;
}

.btn-primary {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
