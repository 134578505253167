footer.main-footer {
  .container {
    & > .row {
      background: #3C3C3C;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .inner-container {
    // width: $inner-conteiner-lg-max-width;
    width: 1350px;
    padding: 0 15px;
  }

  .inner-container .footer-inner-wrap {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: middle;
    }
  }

  p, ul {
    display: block;
    float: left;
    margin: 0;
    line-height: 25px;
  }

  p, a {
    color: #7b7b7b;
    font-size: 13px
  }

  nav {
    float: left;

    li {
      padding-right: 12px;
      margin-right: 12px;
      position: relative;

      &:after {
        content: "|";
        position: absolute;
        right: -1px;
        top: 2px;
        color: #7b7b7b;
      }

      &:last-child:after {
        display: none;
      }
    }

    a {

    }

    a:hover {
      color: #fff;
    }
  }

  .social {
    .widget {
      float: right;
    }
    p {
      margin-right: 10px
    }

    li {
      margin-left: 5px;
    }

    a {
      display: inline-block;
      @include border-radius(4px);
      border: 1px solid #7b7b7b;
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;

      &:hover {
        background: #7b7b7b;
        color: #2E303D;
      }
    }
  }

  .copy {
    p {
      margin-right: 40px;
    }
  }
}
