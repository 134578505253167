// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


form {
  label {
    margin-bottom: 3px;
    font-size: 15px;
  }

  textarea {
    width: 100%;
    padding: 8px;
    font-size: 14px;

    &:focus {
      outline: 1px solid #2e303d;
    }
  }

  input[type=text],input[type=email],input[type=tel] {
    height: 42px;
    width: 100%;
    padding: 0 8px;
    font-size: 14px;

    &:focus {
      outline: 1px solid #2e303d;
    }
  }

  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 22px;
  }

  .btn {
    min-width: 140px;
  }
}
