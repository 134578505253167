// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}


.inner-container {
  width: $inner-conteiner-max-width;
  max-width: 94%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}


@media (min-width: 1000px) {
  .container {
      // width: 1350px;
      max-width: 100%;
  }
}

img {
  max-width: 100%;
}

.container {
    max-width: 100%;
}
