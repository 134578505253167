.btn-primary {
  font-weight: normal;
  padding: 0.65rem 1.6rem;
}

.btn.btn-large {
  padding: 20px 25px;
  font-size: 19px;
}

.btn-secondary {
  &:hover {
    background: $btn-secondary-color;
    border-color: $btn-secondary-color;
    color: #fff;
  }
}
