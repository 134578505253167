// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$grid-breakpoints: (
  xs: 0,
  sm: 10px,
  md: 768px,
  lg: 1000px
);

$container-max-widths: (
  sm: 576px,
  md: 740px,
  lg: 96%,
);

$inner-conteiner-max-width: 850px;
$inner-conteiner-md-max-width: 1000px;
$inner-conteiner-lg-max-width: 90%;

// Colors
$brand-primary:         #FF2950;

// Buttons
$btn-secondary-color: #FF2950;
$btn-secondary-bg: transparent;
$btn-secondary-border: #FF2950;

// // Font Awesome | see: http://fontawesome.io/get-started/
// $fa-font-path:          '~font-awesome/fonts';
