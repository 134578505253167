.banner .nav li {
  @extend .nav-item;
}
.banner .nav a {
  @extend .nav-link;
}

.wrap.container {
  // box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

header.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;

  .container {
    background: none;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: all .3s ease-in-out;
  }

  .inner-container {
    // width: $inner-conteiner-lg-max-width;
    width: 1350px;
    position: relative;
  }

  .phone {
    margin: 0;
    padding-top: 5px;
    line-height: 1em;

    a {
      color: #fff;
      font-size: 13px;
      font-weight: 300;
      line-height: 1em;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .menu-toggle {
    display: block;
    // border: 1px solid #bec3c5;
    width: 30px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 15px;
    top: 0;
    padding-top: 2px;
    // @include border-radius(4px);

    .menu-bar {
      display: block;
      width: 100%;
      height: 1px;
      margin-bottom: 6px;
      background: #fff;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .brand {
    margin: 8px 0 0;

    a {
      display: block;
      background: url(../images/pixelter-logo2-w.png) no-repeat;
      background-size: 100% 100%;
      width: 142px;
      height: 25px;
      text-indent: -9999px;
    }
  }

  .nav-primary {
    text-align: right;
    margin-top: 5px;

    li {
      display: inline-block;
      vertical-align: middle;
      padding: 4px 16px;
      position: relative;

      &.menu-item-has-children > a:before {
        display: none;
      }

      &.menu-item-has-children:hover {
        .sub-menu {
          opacity: 1;
          visibility: visible;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }

      &.menu-item-has-children > a:after {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f0d7";
        margin-left: 5px;

        -webkit-transition: -webkit-transform .3s ease;
        transition: -webkit-transform .3s ease;
        -o-transition: transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease;
      }

      &.opened.menu-item-has-children > a:after {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      .sub-menu {
        position: absolute;
        padding: 20px 0 0;
        text-align: left;
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        -webkit-transform: translate3d(0,20px,0);
        transform: translate3d(0,20px,0);

        &:after,
        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          border-bottom: 12px solid #fff;
          position: absolute;
          left: 15px;
          top: 9px;
          z-index: 10;
        }

        &:before {
          border-bottom: 12px solid #e8e8e8;
          top: 8px;
        }


        li {
          padding: 0;
          background: #fff;
          display: block;
          padding: 6px 30px 6px 20px;
          border-left: 1px solid #e8e8e8;
          border-right: 1px solid #e8e8e8;

          &:first-child {
            padding-top: 14px;
            border-top: 1px solid #e8e8e8;
          }

          &:last-child {
            padding-bottom: 14px;
            border-bottom: 1px solid #e8e8e8;
          }

          a {
            white-space: nowrap;
            color: #373a3c !important;

            &:before {
              display: none;
            }
          }
        }
      }

      &.service-nav {
        .sub-menu {
          li {
            padding: 0;
            border-bottom: 1px solid #fafafa;

            &:last-child {
              border-bottom: 1px solid #e8e8e8;
            }

            &:hover {
              background: #fdfafa;
            }
          }

          .title {
            display: block;
            font-weight: bold;
          }

          a {
            line-height: 1.4em;
            background-size: 22px !important;
            padding-left: 40px;
            padding: 20px 30px 20px 65px;
            display: block;
          }

          .audit-item {
            a {
              background: url(https://pixelter.com/wp-content/uploads/2019/01/audit-icon2.svg) no-repeat 20px center;
              background-size: 20px !important;
            }
          }

          .cro-item {
            a {
              background: url(https://pixelter.com/wp-content/uploads/2019/01/optimization-icon.svg) no-repeat 20px center;
            }
          }

          .shopify-item {
            a {
              background: url(https://pixelter.com/wp-content/uploads/2018/03/shopify-ico.svg) no-repeat 20px center;
            }
          }
        }
      }

      // &.current-menu-item a:after,
      // &.current-page-ancestor a:after {
      //   width: 100%;
      // }

      &.menu-cta {
        border: 1px solid #fff;
        @include border-radius(4px);
        transition: all .3s ease-in-out;
        margin-left: 12px;

        a:before {
          display: none;
        }

        &:hover {
          background: #FF2950;
          border-color: #FF2950;
        }
      }

      a {
        color: #fff !important;
        font-weight: normal;
        font-size: 14px;
        display: inline-block;
        line-height: 26px;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        position: relative;

        &:before {
          content: "";
          display: block;
          height: 2px;
          width: 0;
          background: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          -webkit-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
          position: absolute;
        }

        &:hover,
        &.active {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) and (min-width:1000px) {
  header.banner .inner-container {
    width: 100%;
  }

  header.banner .nav-primary li {
    padding: 4px 13px;
  }
}

@media (max-width: 1080px) and (min-width:1000px) {
  header.banner .nav-primary li {
    padding: 4px 8px;
  }
}

@media (max-width: 1000px) {
  header.banner .container {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  header.banner .nav-primary {
    position: absolute;
    top: 43px;
    left: 15px;
    background: #fff;
    padding: 15px 45px 15px 15px;
    border: 1px solid #fff;
    // @include border-radius(4px);
    display: none;
  }

  header.banner .nav-primary li {
    padding: 0 6px;
    border-top: 1px solid #f3f3f3;
    display: block;
  }

  header.banner .nav-primary li a {
    display: block;
    text-align: left;
    color: #373a3c !important;
  }

  header.banner .nav-primary li.menu-cta a {
    color: #fff !important;
  }

  header.banner .nav-primary li.opened .sub-menu {
    max-height: 1000px;
  }

  header.banner .nav-primary li .sub-menu {
    position: static;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    transform: none;
    padding: 0;

    max-height: 0;
    overflow: hidden;

    &:before,
    &:after {
      display: none;
    }

    li {
      padding: 0 !important;
      border-color: #fff;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  header.banner .nav-primary .menu-close {
    width: 30px;
    height: 30px;
    background: url(../images/close.svg) no-repeat;
    background-size: 30px;
    display: block;
    margin: 0 auto;

    i {
      display: none;
    }
  }

  header.banner .nav-primary .menu-main-container {
    padding-top: 13px;
  }

  html.menu-visible,
  html.menu-visible body {
    height: 100%;
    // overflow: hidden;
  }

  header.banner .nav-primary li a {
    font-size: 16px !important;
  }

  body.scrolled header.banner .nav-primary {
    border: 1px solid #e8e8e8;
  }

  header.banner .brand a {
    margin: 0 auto;
  }

  header.banner .phone {
    text-align: center;
    display: none;
  }

  body header.banner .nav-primary {
    margin-top: 0;
  }

  header.banner .main-nav-wrap {
    position: absolute;
    top: 0;
    left: 0;
  }

  header.banner .main-nav-wrap .mobile-nav-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000000;
    text-align: center;
    background: rgba(46, 48, 61, 0.82);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
  }

  header.banner .main-nav-wrap .nav-primary {
    display: block;
    position: fixed;
    top: -100vh;
    padding: 15px 15px 15px 15px;
    left: 5vw;
    width: 90vw;
    z-index: 10000000;
    text-align: center;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    -o-transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out;
  }

  header.banner .nav-primary li.menu-cta {
    background: #FF2950;
    border-color: #FF2950;
    margin-left: 0;

    a {
      color: #fff;
      text-align: center;
    }
  }

  html.menu-visible header.banner .main-nav-wrap .mobile-nav-overlay {
    opacity: 1;
    visibility: visible;
  }

  header.banner .main-nav-wrap .nav-primary.visible {
    -webkit-transform: translate3d(0, calc(100vh + 5vw),0);
    transform: translate3d(0, calc(100vh + 5vw),0);
  }
}

@media (max-width: 1000px) {
  header.banner .container {
    padding-top: 15px;
    padding-bottom: 15px;

    h1 {
      margin: 0;
    }
  }

  .hp-content .top-banner .banner-content {
    padding-top: 90px;
    height: auto;
    padding-bottom: 60px;
  }

  header.banner .inner-container {
    position: relative;
  }

  body:not(.header-bg),
  body.tools,
  body.pricing-page,
  body.contact,
  body.about,
  body.case-studies,
  body.scrolled {
    header.banner .menu-toggle {
      color: #bec3c5;

      .menu-bar {
        background: #2e303d;
      }
    }

  }


  header.banner .brand {
    margin-top: 0;
  }

  header.banner .nav-primary li.menu-item-has-children>a:after {
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
  }

  header.banner .nav-primary li .sub-menu a,
  header.banner .nav-primary li.service-nav .sub-menu a {
    padding: 12px 15px 12px 15px;
    background: none !important;

    .title {
      font-weight: normal;
    }

    .nav_desc {
      display: none;
    }
  }
}

body:not(.header-bg),
body.scrolled,
body.page-template-templates:not(.header-bg) {
  header.banner {
    .container {
      // background: #fff;
      padding-top: 15px;
      padding-bottom: 15px;
      // border-bottom: 1px solid #e8e8e8;
    }

    .phone a {
      color: #333;
      border-left-color: #b3b3b3;
    }

    .brand a {
      background-image: url(../images/pixt-logo-orange.png);
    }

    .nav-primary li a:before {
      background: #f6c475;
    }

    .nav-primary {
      li a {
        color: #333 !important;

        &:hover,
        &:active {
          color: #000 !important;
        }
      }

      li.menu-cta {
        background: #FF2950;
        border-color: #FF2950;

        a {
          color: #fff !important;
        }

        &:hover {
          background: #BF2447;
          border-color: #BF2447;
        }
      }
    }
  }
}

body:not(.header-bg),
body.scrolled,
body.page-template-templates:not(.header-bg) {
  header.banner {
    .container {
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
